import React from 'react';
import navLinks from '../../data/navLinks';
import { Link } from 'react-router-dom';
import Login from '../login/login';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

const Cards = ({ isMobile, name, path, imagePath, desc, mobile }) => {
  // Generate a dynamic image path based on the name (assuming images are named similarly)
  const linkPath = mobile && path === '/online-results' ? '/online-results' : path;
  return (
    // 

    <Grid item xs={6} sm={3} lg={2} xl={1.5} md={3} className='animated animatedFadeInUp fadeInUp'>
      <Link to={linkPath}>
        <Card
          sx={{
            borderRadius: '16px', // Adjust as needed
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adjust as needed
            transition: '0.5s',
            padding: 0.2,
            '&:hover': {
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Adjust as needed for hover effect
            },
          }}>
          <CardMedia
            component="img"
            height="194"
            image={imagePath}
            alt="Paella dish"
          />
          <CardContent>
            <Typography gutterBottom variant="span" className='text-sm lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center' component="div">
              {name}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>


    // </Link>
  );
};
{/* <Card className="max-w-sm w-full animated animatedFadeInUp fadeInUp" >
        <img
          src={imagePath}
          alt={name}
          className="w-full md:h-28 lg:h-48"
        />
        <p className='text-sm lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center'>{name}</p>
       
      </Card> */}
function home({ isMobile }) {
  return (
    <div >
      <Box sx={{ flexGrow: 1, m: 2 }}>
        {isMobile ? (
          <>
            <div className="col-span-2 h-auto mb-9 md:col-span-1 sm:col-span-1 flex flex-col items-start justify-start animated animatedFadeInUp fadeInUp">
              {/* <Login /> */}
            </div>
            <div className="col-span-2  md:col-span-1 sm:col-span-2 pa-1 max-w-3xl p-1 bg-gray-50 dark:bg-blue-400 w-full">
              <div className="grid grid-cols-2 gap-2 md:grid-cols-3 sm:grid-cols-4 lg:grid-cols-4 col-span-4">
                {navLinks.map((link, index) =>
                  index === 0 ? null : <Cards key={index} name={link.name} path={link.path} imagePath={link.image} desc={link.description} mobile={true} />
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              {navLinks.map((link, index) =>
                index === 0 ? null :
                  <Cards key={index} name={link.name} path={link.path} imagePath={link.image} desc={link.description} mobile={false} />
              )}
            </Grid>
          </>
        )}
      </Box>
    </div>
  );
}

export default home;
