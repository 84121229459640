import React from 'react';
import { FaTrash } from 'react-icons/fa';
export default function TableList({ selectedItems,handleRemoveItem,formatNumber}) {
    return (
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Description
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {selectedItems.length === 0 ? (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td className="w-4 p-4" colSpan={3}> No record found</td>
                        </tr>
                    ) :
                        selectedItems.map((item, index) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {item.name}
                                </th>
                               
                                <td className="px-6 py-4">
                                 {formatNumber(parseFloat(item.amount))}
                                </td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => handleRemoveItem(item)}
                                        className="flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                        <FaTrash /> remove
                                    </button> 
                                     {/* <a onClick={() => handleRemoveItem(item)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Remove</a> */}
                                </td>
                            </tr>
                        )
                        )
                    }

                </tbody>
            </table>
        </div>


    );
}
