import React, { useState } from 'react'

import Select from 'react-select';
import TableList from './TableList';
import { format } from 'date-fns';
import { Button, Modal, Checkbox, Label, Datepicker, TextInput, Pagination, Spinner, FileInput } from "flowbite-react";
import { FaSearch, FaCheckCircle } from 'react-icons/fa';
import _ from 'lodash';
export default function Appointment({
    appointment_type,
    isModalOpen,
    handleCheckboxChange,
    handleSelectedTest,
    filteredItems,
    handleRemoveItem,
    setIsModalOpen,
    filter,
    setFilter,
    selectedItems,
    formatNumber,
    doctorList,
    handleSelectItem,
    itemListoptions,
    errors,
    formData,
    handleChange,
    handleSubmit,
    refs,
    setFormData,
    loading,
    apiEndPoint,
    fetchAppointmentTypeList,
}) {


    const [currentPage, setCurrentPage] = useState(1);
    const [isFullBook, setisFullBook] = useState(false);
    const [isFullBookBtn, setisFullBookBtn] = useState(false);
    const pageSize = 12; // Number of items per page

    const [selectedDate, setSelectedDate] = useState(null);
    const currentDate = new Date();
    const currentHour = currentDate.getHours(); // Get current hour
    if (currentHour >= 17) { // Check if the current time is 6 PM or later
        currentDate.setDate(currentDate.getDate() + 3); // Add one day to the current date
    } else {
        currentDate.setDate(currentDate.getDate() + 2); // Add one day to the current date
    }
    const doctorListoptions = doctorList.map(item => ({
        value: item.doctorCode, // Define the value for each option
        label: item.doctor_name, // Define the label (display text) for each option
    }));
    // Paginate function
    const paginate = (array, page_size, page_number) => {
        const paginatedArray = _.chunk(array, page_size);
        return paginatedArray[page_number - 1] || [];
    };
    const paginatedItems = paginate(filteredItems, pageSize, currentPage);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData({
                ...formData,
                image: file,
            });
        }

    };
    // Get paginated items

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleTypeChange = selectedOption => {
        setFormData({
            ...formData,
            booking_type: selectedOption.value, // Update zipcode in formData with the selected option's value
            booking_type_name: selectedOption.label, // Update zipcode in formData with the selected option's value
        });
        fetchAppointmentTypeList(selectedOption.value)
    }
    const handleDoctor = selectedOption => {
        setFormData({
            ...formData,
            booking_doctor: selectedOption.value, // Update zipcode in formData with the selected option's value
            booking_doctor_name: selectedOption.label, // Update zipcode in formData with the selected option's value
        });
    }

    const handleDateChange = async (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setFormData({ ...formData, booking_date: formattedDate });
        setSelectedDate(date);
        try {
            const response = await fetch(`${apiEndPoint}check-schedule`, {
                method: 'POST', // Assuming this is a POST request based on your previous examples
                headers: {
                    'Content-Type': 'application/json', // Adjust content type as needed
                    // Add other headers if required
                },
                body: JSON.stringify({
                    date_schedule: formattedDate,
                })
            }
            );
            const result = await response.json();
            if (parseInt(result.data) >= process.env.REACT_APP_TOTAL_BOOK) {
                setisFullBook(true)
                setisFullBookBtn(true);
            } else {
                setisFullBookBtn(false);
                setisFullBook(false)
            }
        } catch (error) {
        } finally {
        }
    };

    return (
        <div style={{ minHeight: '58vh' }}>
            <form onSubmit={handleSubmit} >
                <div className="relative grid grid-cols-3 gap-4 md:grid-cols-3 sm:grid-cols-4 lg:grid-cols-4 animated animatedFadeInUp fadeInUp ">
                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                        <label htmlFor="appointmenttype" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Appointment Type <span className='text-red-100'>*</span> </label>
                        <Select id="searchable-select"
                            value={appointment_type.find(option => option.value === formData.booking_type)}
                            onChange={handleTypeChange}
                            ref={refs.booking_type}
                            options={appointment_type} />
                        {!formData.booking_type && errors.booking_type && <p className="text-red-500 text-sm">{errors.booking_type}</p>}
                    </div>
                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                        <label htmlFor="last_name" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Appointment Date <span className='text-red-100'>*</span> </label>

                        <Datepicker
                            title="Appointment Date"
                            id="booking_date"
                            ref={refs.booking_date}
                            selected={selectedDate}
                            onSelectedDateChanged={handleDateChange}
                            minDate={currentDate}
                        />
                        {!formData.booking_date && errors.booking_date && <p className="text-red-500 text-sm">{errors.booking_date}</p>}

                    </div>
                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                        <label htmlFor="searchable-select" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Select Doctor
                        </label>
                        <Select
                            value={doctorListoptions.find(option => option.value === formData.booking_doctor)}
                            onChange={handleDoctor}
                            ref={refs.booking_doctor}
                            options={doctorListoptions}
                            className="z-50"
                            id="searchable-select" />
                        {!formData.booking_doctor && errors.booking_doctor && <p className="text-red-500 text-sm">{errors.booking_doctor}</p>}
                    </div>
                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                        <div>
                            <div>
                                <Label htmlFor="file-upload-helper-text" value="Upload Doctor's Request" />
                            </div>
                            <FileInput onChange={handleFileChange} accept="image/png" id="file-upload-helper-text" helperText="File type .jpg or .png only (Max 3MB)" />
                        </div>
                    </div>
                </div>
                <div className="flex mt-2 justify-between items-center text-sm font-medium text-gray-500 dark:text-gray-300">
                    <h5 className="text-sm lg:text-sm text-left font-medium text-gray-900 dark:text-white">Appointment <span className='text-red-100'>*</span></h5>
                    <Button disabled={isFullBookBtn} onClick={handleSelectItem} >Select Item</Button>
                </div>
                <div className="border border-b mt-2 border-gray-200"></div>
                <div className="mt-3 -z-0">
                    <TableList selectedItems={selectedItems} handleRemoveItem={handleRemoveItem} formatNumber={formatNumber} />
                </div>
            </form>
            <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)} size="xl" position='center' className="fixed inset-0 w-screen flex items-center justify-center" sizes="max-w-lg">
                <Modal.Header>List of Items</Modal.Header>
                <div className="relative m-2 mb-4">
                    <TextInput
                        type="text"
                        className="form-input w-full pr-10"
                        placeholder="Filter items"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <FaSearch className="text-gray-400" />
                    </div>
                </div>
                <div className="border border-b border-gray-200"></div>
                <Modal.Body className='pt-2'>
                    <div >
                        <div className='space-y-1 p-1 '>
                            {loading === true ? (
                                <div className="flex justify-center items-center">
                                    <Spinner aria-label="Loading..." size="xl" />
                                </div>
                            ) : (
                                <ul >
                                    {paginatedItems.map((item, index) => (
                                        <li key={index} onClick={() => handleCheckboxChange(item)} id={`${item.id}`} className="py-2 flex items-center justify-start " >
                                            <Checkbox
                                                className="mr-2"
                                                id={`${item.id}`}
                                                checked={selectedItems.some((selected) => selected.id === item.id)}
                                            />

                                            <div className="flex w-full items-center justify-between space-x-4">
                                                <div><Label >{item.name}</Label></div>
                                                <div><Label >{formatNumber(parseFloat(item.amount))}</Label></div>
                                            </div>
                                        </li>
                                    ))}

                                </ul>
                            )}

                            <Pagination
                                currentPage={currentPage}
                                totalPages={Math.ceil(filteredItems.length / pageSize)}
                                onPageChange={handlePageChange}
                            />
                            
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="flex justify-between space-x-4">
                    <Button
                        className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center text-center"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Close
                    </Button>
                    <Button onClick={handleSelectedTest}>Select Item</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isFullBook} onClose={() => setisFullBook(false)} position="center" size="md" popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <FaCheckCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Sorry, this schedule is fully booked.
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button onClick={() => setisFullBook(false)} >
                                {"Close"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}
