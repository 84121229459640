import React from 'react'

export default function Location() {
    return (
        <div className="max-w-3xl mx-auto p-4 ">
            <div>
            <h3 className="text-lg font-semibold mb-2 animated animatedFadeInUp fadeInUp">Location</h3>
                <iframe
                    title="Happydoc Diagnostics"
                    width="100%"
                    height="450"
                    className="animated animatedFadeInUp fadeInUp"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15701.430701663803!2d123.88212215751611!3d10.31323344805753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33a99950eb83b083%3A0xfef4e591251f8255!2sHappydoc%20Diagnostics!5e0!3m2!1sen!2sph!4v1720690824727!5m2!1sen!2sph" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}
