import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import { useNavigate } from 'react-router-dom';
import Main from './MainStyled'; // Import Main styled component
import AppBar from './AppBarStyled'; // Import AppBar styled component
import DrawerHeader from './DrawerHeader'; // Import AppBar styled component
import BottomNavigationComponent from './BottomNavigationComponent'; // Import the BottomNavigationComponent

import HomeContent from '../pages/Patient/HomeContent';
import AppointmentContent from '../pages/Patient/AppointmentContent';
import ResultContent from '../pages/Patient/ResultContent';

import PatientNavigation from '../data/PatientNavigation';
const drawerWidth = 240;
const appBarColor = "#107DAC"; // Example color

const PersistentDrawerLeft = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Change Schedule'); // Initial selected item

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setIsMobile(true); // Adjust the breakpoint as needed
        setOpen(false); // Close the drawer on mobile
      } else {
        setIsMobile(false);
        setOpen(true); // Keep the drawer open on larger screens
      }
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (item) => {
    setSelectedItem(item);
    if (isMobile) {
      setOpen(false); // Close the drawer on mobile
    }
  };

  const renderContent = () => {
    switch (selectedItem) {
      case 'Home':
        return <HomeContent />;
      case 'Change Schedule':
        return <AppointmentContent />;
      case 'Results':
        return <ResultContent />;
      case 'Logout':
        localStorage.clear();
        navigate('/');
        break;
      default:
        return <AppointmentContent />;
    }
  };


  const Toolbarbtn = () => {
    if (!isMobile) {
      return (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{  mr: 2, ...(open && { display: 'none' }) }}
          
        >
          <MenuIcon  sx={{ color: 'white' }} />
        </IconButton>
      )
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: appBarColor }} position="fixed" open={open}>
        <Toolbar>
          <Toolbarbtn  />
          <Typography variant="h6" noWrap component="div">
            Happydoc Online Booking
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: appBarColor, // Set the background color here
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader  sx={{ color: 'white',textAlign:'left' }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon  sx={{ color: 'white' }}   /> : <ChevronRightIcon   sx={{ color: 'white' }} />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {PatientNavigation.map((text) => (
            <ListItem
              key={text.name}
              button
              onClick={() => handleListItemClick(text.name)}
              selected={selectedItem === text.name}
              sx={{ color: 'white' }} // Set the text color to white
            >
              <ListItemIcon sx={{ color: 'white' }} >
                {text.icon}
              </ListItemIcon>
              <ListItemText primary={text.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {renderContent()}
      </Main>
      <BottomNavigationComponent
        isMobile={isMobile}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </Box>
  );
};

export default PersistentDrawerLeft;
