import { TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function Login() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [error, setError] = useState(true);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const apiEndPoint = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiEndPoint}login-portal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: formData.username,
          password: formData.password,
        })
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Login successful:', data);

        // Store user data in local storage
        localStorage.setItem('user', JSON.stringify(data.user));

        // Redirect to dashboard or another route
        navigate('/portal/appointment'); // Adjust route as needed
      } else {
        setError(false)
        console.error('Login failed:', response.statusText);
        // Handle login failure, e.g., display error message
      }
    } catch (error) {
      setError(false)
      console.error('Error submitting form:', error);
      // Handle any network or other errors
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div >
      <Box
        sx={{
          flexGrow: 1,
          background:'white',
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          alignItems: 'center',     // Center vertically
          height: '80vh'           // Ensure full viewport height if needed
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={3} lg={2} xl={3} md={3} className='animated animatedFadeInUp fadeInUp'>
            <Card
              sx={{
                borderRadius: '1px', // Adjust as needed
                boxShadow: 'none', // Adjust as needed
                transition: '0.5s',
                padding: 5,
              }}>
              <form className="space-y-6 w-full" onSubmit={handleSubmit}>
                <h5 className="text-2xl text-center font-medium text-gray-900 dark:text-white">Login Here</h5>
                <p className=" text-left mb-2 font-medium text-gray-900 dark:text-white">To change your schedule</p>
                <div>
                  <label htmlFor="patientID" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                  <TextInput
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Password</label>
                  <TextInput
                    type="password"
                    id="password"
                    name="password"
                    placeholder="••••••••"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="flex items-start">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        type="checkbox"
                        value=""
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                      />
                    </div>
                    <label htmlFor="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Remember me</label>
                  </div>
                  {/* <a href="#" className="ms-auto text-sm text-blue-700 hover:underline dark:text-blue-500">Lost Password?</a> */}


                </div>
                <div> {!error && <p className="text-red-500 text-sm">Incorrect username or password</p>}</div>
                <button
                  type="submit"
                  className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Login to your account
                </button>
                {
                  isMobile ? (
                    <Link to="/" className='mt-3'>
                      <button
                        type='button'
                        className="w-full mt-3  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Back to Home
                      </button>
                    </Link>
                  ) : ('')
                }
              </form>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
