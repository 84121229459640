import React, { useCallback, useEffect, useState } from 'react'
import FastingTest from '../Booking/Forms/FastingTest';
// import { Card } from 'flowbite-react';

export default function Userguide() {
    const apiEndPoint = process.env.REACT_APP_API_URL;
    const [fastingList, setfastingList] = useState([]);
    const [isFasting, setisFasting] = useState(false);
    const [isModalOpen1, setisModalOpen1] = useState(false);
    const handleFasting = ()=> {
        setisModalOpen1(true);
    }
    const fetchCommitment = useCallback(async () => {
        try {
            const response = await fetch(`${apiEndPoint}get-patient-commitment`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            if (result) {
                // setDataPolicy(data.policy.description);
                // setDataRefund(data.patientrefundpolicy.description);
                setfastingList(result.exams);
            }
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint]);
    useEffect(() => {
        fetchCommitment();
    }, [fetchCommitment]);
    return (
        <div className="max-w-3xl mx-auto p-4">
            <div>
                <h2 className="text-xl font-semibold mb-4 animated animatedFadeInUp fadeInUp">FAST LANE/WALK-THRU</h2>
                <p className='animated animatedFadeInUp fadeInUp'>To ensure a seamless patient experience, we offer our laboratory walk-thru/fast lane service.</p>
                <ol className="list-decimal list-inside mt-4">
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">You may schedule your appointment by submitting it through the online portal.</li>
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">You must wait for a confirmation message via text or email regarding your scheduled appointment.</li>
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">Three hundred pesos is the booking fee.</li>
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">To secure the slot, kindly settle payment via Online bank deposit or transfer, Gcash, or Paymaya.</li>
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">Once your payment is confirmed, you will receive a text stating your appointment date and time.</li>
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">Our staff will call you about the preparations needed to comply before your arrival on your appointment date to avoid delays.</li>
                    <li  onClick={handleFasting}  className="mt-2 animated animatedFadeInUp fadeInUp">
                        For other diagnostic blood tests, you may check with your physician. Some blood tests may require at least 10 hours of fasting before extraction. Please see <a href="#"  className="text-blue-500">this link</a> for the list of laboratory tests that require fasting.
                    </li>
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">Please bring a valid ID and original doctor’s request (if any) on the day of your appointment.</li>
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">Once you arrive at the facility, you may proceed to blood extraction.</li>
                    <li className="mt-2 animated animatedFadeInUp fadeInUp">Wait for your result to be sent via email within 24-48 hours.</li>
                </ol>
            </div>
            <FastingTest isModalOpen1={isModalOpen1}  setisModalOpen1={setisModalOpen1} fastingList={fastingList} />
        </div>
    )
}
