import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import Home from '../../pages/home/home';
import Login from '../../pages/login/login';
import Information from '../../pages/Patient/Profile/Information';
import Schedule from '../../pages/Patient/appointment/Schedule';
import ResultFile from '../../pages/Patient/results/ResultFile';
import Booking from '../../pages/Booking/Booking';
import Services from '../../pages/Services/Services';
import Doctors from '../Doctors/Doctors';
import Userguide from '../UserGuide/Userguide';
import Location from '../Location/Location';
import ResultContent from '../Patient/ResultContent';
import Results from '../Results/Results';

const AuthRoutes = ({ isAuthenticated, isMobile }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        const isPortalRoute = location.pathname.startsWith('/portal');

        if (isPortalRoute && !user && !isAuthenticated) {
            // Redirect to login if trying to access portal routes without authentication
            navigate('/');
        }
    }, [location,navigate, isAuthenticated]);

    return (
        <div className={`${isMobile ? 'mx-auto' : 'mx-auto max-h-full'}`}>
            <Routes>
                {/* Public routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Home isMobile={isMobile} />} />
                <Route path="/online-results" element={<Results isMobile={isMobile} />} />
                <Route path="/results" element={<ResultFile isMobile={isMobile} />} />
                <Route path="/users-guide" element={<Userguide isMobile={isMobile} />} />
                <Route path="/location" element={<Location isMobile={isMobile} />} />
                <Route path="/online-booking" element={<Booking isMobile={isMobile} />} />
                <Route path="/our-doctors" element={<Doctors isMobile={isMobile} />} />

                {/* Protected routes */}
                <Route path="/portal/appointment" element={<Schedule isMobile={isMobile} isAuthenticated={isAuthenticated} />} />
                <Route path="/portal/profile" element={<Information isMobile={isMobile} />} isAuthenticated={isAuthenticated} />
                <Route path="/portal/dashboard" element={<Services isMobile={isMobile} />} isAuthenticated={isAuthenticated} />
                <Route path="/portal/results" element={<ResultContent isMobile={isMobile} isAuthenticated={isAuthenticated} />} />

                {/* Handle 404 - Not Found */}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </div>
    );
};

export default AuthRoutes;
