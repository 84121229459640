import React, { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Modal as Modals} from "flowbite-react";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PatientCard = ({ appointment, paymentlist, formData, setFormData, handleSubmit, openChangeModal, setOpenChangeModal, text, setText, isFullBook, setisFullBook }) => {
    // const { appointment.name, age, sex, contact, birthdate } = appointment;
    // const { dateSchedule, referenceNo, doctorsName, appointmentType } = appointment;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const apiEndPoint = process.env.REACT_APP_API_URL;


    // State for managing modal open/close
    const [openModal, setOpenModal] = useState(false);

    // Function to handle opening the modal
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    // Function to handle opening the change schedule modal
    const handleOpenChangeModal = () => {
        setOpenChangeModal(true);
        setFormData({
            ...formData,
            patient_id: appointment.patient_id,
        });

    };

    // Function to handle closing the change schedule modal
    const handleCloseChangeModal = () => {
        setOpenChangeModal(false);
    };
    const formatNumber = (amount) => {
        if (!amount) return parseFloat(0);
        return parseFloat(amount).toLocaleString('en-PH', {
            style: 'currency',
            currency: 'PHP',
        });
    }
    const checkstatus = (item) => {
        if (item.process_by && !item.rebook_fee && !item.rebook_refnum) {
            return 'Confirmed';
        } else if (item.process_by && item.rebook_fee && item.rebook_refnum) {
            return 'Confirmed ';
        } else {
            return 'Pending';
        }
    }

    const handleChange = (event) => {
        const { value } = event.target;
        const accountnumber = paymentlist.find(option => option.value === value);
        setFormData({
            ...formData,
            payment_type_id: parseInt(value),
            payment_accountno: accountnumber.account
        });
    };
    const handleChangeDate = async (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            schedule_date: value,
        });
        try {
            const response = await fetch(`${apiEndPoint}check-schedule`, {
                method: 'POST', // Assuming this is a POST request based on your previous examples
                headers: {
                    'Content-Type': 'application/json', // Adjust content type as needed
                    // Add other headers if required
                },
                body: JSON.stringify({
                    date_schedule: value,
                })
            }
            );
            setText('Sorry, this schedule is fully booked.')
            const result = await response.json();
            if (parseInt(result.data) >= process.env.REACT_APP_TOTAL_BOOK) {
                setisFullBook(true)
                // setisFullBookBtn(true);
            } else {
                // setisFullBookBtn(false);
                setisFullBook(false)
            }
        } catch (error) {
        } finally {
        }
    };

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFormData({
            ...formData,
            payment_file: file,
        });
        // setSelectedFile(file); 
        console.log(file)
        console.log(formData.payment_file, '123123')
    };
    const handleChangereference = (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            payment_reference: value,
        });
    };


    const totalamount = appointment.rebook_fee !== 0 ? Math.max(appointment.total_fee) + Math.max(appointment.rebook_fee) : Math.max(appointment.total_fee);
    const currentDate = new Date();
    const currentHour = currentDate.getHours(); // Get current hour
    if (currentHour >= 17) { // Check if the current time is 6 PM or later
        currentDate.setDate(currentDate.getDate() + 3); // Add one day to the current date
    } else {
        currentDate.setDate(currentDate.getDate() + 2); // Add one day to the current date
    }
    const formattedDate = currentDate.toISOString().split('T')[0];
    return (
        <>
            <Grid item xs={12} md={5}>
                <Card sx={{ margin: isMobile ? 0 : 2, boxShadow: 3 }}>
                    <CardContent>
                        <Typography variant="h6" component="div" gutterBottom>
                            Name: {appointment.name}
                        </Typography>
                        <Divider sx={{ marginY: 1 }} />
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="body1" color="textPrimary">
                                    Doctor: {appointment.doctor_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" color="textPrimary">
                                    Date Schedule: {appointment.date_schedule}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" color="textPrimary">
                                    Reference No: {appointment.reference_no}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" color="textPrimary">
                                    Appointment: {appointment.registration_type}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" color="textPrimary">
                                    Payment: {appointment.payment_type}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" color="textPrimary">
                                    Status: {checkstatus(appointment)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'row' : 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 1,
                        }}
                    >
                        <Button
                            size="small"
                            color="success"
                            variant="outlined"
                            onClick={handleOpenModal}
                        >
                            View Details
                        </Button>
                        <Button size="small" color="secondary" variant="outlined" onClick={handleOpenChangeModal}>
                            Change Schedule
                        </Button>
                    </CardActions>
                </Card>
            </Grid>

            {/* Full Screen Modal */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Card sx={{ width: '95%', top: '20%', maxWidth: 600, minHeight: 500, overflow: 'auto' }}>
                    <Grid container spacing={0}>
                        {/* Column 2: Patient Information */}
                        <Grid item xs={12} md={12}>
                            <div style={{ padding: 10 }}>
                                <Typography gutterBottom variant="h7" component="div">
                                    Name : {appointment.name}
                                </Typography>
                                <Grid container spacing={1} sx={{ marginTop: 1 }}>
                                    <Grid item xs={4} md={6}>
                                        <Typography variant="body2" color="text.secondary">
                                            Age: {appointment.age}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={8} md={6}>
                                        <Typography variant="body2" color="text.secondary">
                                            Contact: {appointment.contact}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={6}>
                                        <Typography variant="body2" color="text.secondary">
                                            Sex: {appointment.sex}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} md={6}>
                                        <Typography variant="body2" color="text.secondary">
                                            Birthdate: {appointment.birthdate}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Email: {appointment.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Address: {appointment.streetbldg}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div style={{ padding: 10 }}>
                                <Typography gutterBottom variant="h7" component="div">
                                    Doctor's Name :  {appointment.doctor_name}
                                </Typography>
                                <Grid container spacing={1} sx={{ marginTop: 1 }}>
                                    <Grid item md={6} xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Date Schedule: {appointment.date_schedule}
                                        </Typography>
                                    </Grid>

                                    <Grid item md={6} xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Reference No: {appointment.reference_no}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Typography variant="body2" color="text.secondary">
                                            Appointment: {appointment.registration_type}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        {/* Column 3: Appointment Details */}

                    </Grid>
                    {/* Table */}
                    <CardContent>
                        <Divider style={{ marginBottom: 5 }} />
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}>Description</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {appointment.items.map((exam, index) => (
                                        <TableRow key={index}>
                                            <TableCell colSpan={2}>{exam.ItemDescription}</TableCell>
                                            <TableCell>{formatNumber(exam.Amount)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableBody>
                                    <TableRow >
                                        <TableCell colSpan={3}><br /></TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell colSpan={2} align='right'>Charges Fee</TableCell>
                                        <TableCell>{formatNumber(appointment.charges_fee)}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell colSpan={2} align='right'>Convenience Fee</TableCell>
                                        <TableCell>{formatNumber(appointment.convenience_fee)}</TableCell>
                                    </TableRow>
                                    {
                                        appointment.rebook_fee !== 0 ? (
                                            <TableRow >
                                                <TableCell colSpan={2} align='right'>Reschedule Fee</TableCell>
                                                <TableCell>{formatNumber(appointment.rebook_fee)}</TableCell>
                                            </TableRow>
                                        ) : ('')
                                    }

                                    <TableRow >
                                        <TableCell colSpan={2} align='right'>Total Fee</TableCell>
                                        <TableCell>{formatNumber(totalamount)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                    <CardActions className='flex justify-end'>
                        <Button size="small" color="secondary" variant="outlined" onClick={handleCloseModal}>Close</Button>
                    </CardActions>
                </Card>
            </Modal>

            {/* Change Schedule Modal */}
            <Modal
                open={openChangeModal}
                onClose={handleCloseChangeModal}
                aria-labelledby="change-modal-title"
                aria-describedby="change-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Card sx={{ width: '95%', maxWidth: 600, minHeight: 600, overflow: 'auto' }}>
                    <CardContent >
                        <Typography variant="h6" component="h2" gutterBottom>
                            Change Schedule
                        </Typography>
                        <Divider style={{ marginBottom: 5 }} /><br />
                        <div className="w-full items-center justify-center flex" >
                            <div>
                                <h1 className='text-2xl lg:text-md mb-3 font-bold tracking-tight text-gray-900 dark:text-white text-center'>{formatNumber(parseFloat(300))}</h1>
                                <h1 className='text-xl lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center'>Re-schedule Fee</h1>
                            </div>
                        </div><br />
                        <FormControl sx={{ minWidth: '100%', height: '100%' }} size="small">
                            <label>Payment Method  {!formData.payment_type_id && <span className="text-red-500 text-sm">(*This field is required)</span>}</label>
                            <Select
                                labelId="paymenttype"
                                id="paymenttype"
                                value={formData.payment_type_id}
                                onChange={handleChange}
                            >
                                {paymentlist.map((item) => (
                                    <MenuItem value={item.value}>{item.label}</MenuItem>
                                ))}
                            </Select>


                            <label className='mt-3'>Account Number {!formData.payment_accountno && <span className="text-red-500 text-sm">(*This field is required)</span>}</label>
                            <TextField
                                value={formData.payment_accountno}
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                                size="small"
                            />
                            <label className='mt-3'>Reschedule Date {!formData.schedule_date && <span className="text-red-500 text-sm">(*This field is required)</span>}</label>
                            <TextField
                                type="date"
                                value={formData.schedule_date}
                                fullWidth
                                onChange={handleChangeDate}
                                inputProps={{
                                    min: formattedDate,
                                }}
                                id="outlined-size-small"
                                size="small"
                            />

                            <label className='mt-3'>Upload Proof of Payment {!formData.payment_file && <span className="text-red-500 text-sm">(*This field is required)</span>}</label>
                            <TextField
                                variant="standard"
                                id="upload-file"
                                type="file"
                                fullWidth
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    inputProps: {
                                        accept: 'image/*',
                                    },
                                }}
                                onChange={handleFileChange}
                            />
                            <label className='mt-3'>Reference Number {!formData.payment_reference && <span className="text-red-500 text-sm">(*This field is required)</span>}</label>
                            <TextField
                                value={formData.payment_reference}
                                fullWidth
                                id="outlined-size-small"
                                size="small"
                                onChange={handleChangereference}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </CardContent>
                    <Divider style={{ marginBottom: 5 }} />
                    <CardActions className='flex justify-between'>
                        <Button size="small" color="success" variant="outlined" onClick={handleCloseChangeModal}>Close</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </CardActions>
                </Card>
            </Modal>

            <Modals show={isFullBook} onClose={() => setisFullBook(false)} position="center" size="md" popup>
                <Modals.Header />
                <Modals.Body>
                    <div className="text-center">
                        <FaCheckCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Your Reschedule has been successfully submitted!
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button onClick={() => setisFullBook(false)} >
                                {"Close"}
                            </Button>
                        </div>
                    </div>
                </Modals.Body>
            </Modals>
            {/* <Modal open={isFullBook}
                aria-labelledby="change-modal-title"
                aria-describedby="change-modal-description"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Card sx={{ width: isMobile ? '95%' : '25%', maxWidth: 600, overflow: 'auto' }}>
                    <CardContent >
                        <div className="text-center">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                {text}
                            </h3>
                            <div className="flex justify-center gap-4">
                                <Button onClick={() => setisFullBook(false)} >
                                    {"Close"}
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Modal> */}
        </>
    );
};

export default PatientCard;
