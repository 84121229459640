import React from 'react';
import ServicesList from '../../data/Services';
import { Link } from 'react-router-dom';
import { Card } from "flowbite-react";
import NavbarSidebarLayout from '../../layouts/NavbarSidebarLayout';
const Cards = ({ isMobile, name, path, imagePath, desc }) => {
  // Generate a dynamic image path based on the name (assuming images are named similarly)


  return (
    <Link to={path}>
      <Card className="max-w-sm w-full p-0" >
        <img
          src={imagePath}
          alt={name}
          className={`${isMobile ? "w-full h-20 " : 'w-full h-54 '}`} // Adjust the width and height as needed
        />
        <h5 className={`${isMobile ? 'text-sm font-bold tracking-tight text-gray-900 dark:text-white text-center' : 'text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center'}`}>{name}</h5>
        {!isMobile ? (<p className='font-normal text-lg text-gray-700 dark:text-gray-400 text-center'>{desc}</p>) : ('')}
      </Card>
    </Link>
  );
};
function Services({ isMobile }) {
  return (
    <NavbarSidebarLayout>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 col-span-4">
        {/* Content for the second div */}
        {ServicesList.map((link, index) =>
          index === 0 ? '' : <Cards key={index} isMobile={isMobile} name={link.name} path={link.path} imagePath={link.image} desc={link.description} />
        )}
      </div>
    </NavbarSidebarLayout>
  );
}

export default Services;
