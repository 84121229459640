import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function PatientOption({
    IsPatientOption,
    handleSetPatientOpition,
    SetIsPatientOption,
    setisPrivacyNotice
}) {
    const linkPath = '/online-results';
    const rebookPath = '../reschedule.png';
    const newPath = '../booking-calendar.png';

    const navigate = useNavigate();
    const handlepath = (path) => {
        if (path === 'login') {
            setisPrivacyNotice(false)
            SetIsPatientOption(false)
            navigate('/login');
        } else if (path === 'new') {
            setisPrivacyNotice(true)
            SetIsPatientOption(false)
            navigate('/online-booking')
        }
    }

    return (
        <div>
            {
                IsPatientOption && (
                    <div className="modal animated animatedFadeInUp fadeInUp ">
                        <div className="">
                            <Box sx={{ flexGrow: 1, m: 2 }}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item xs={6} sm={3} lg={2} xl={1.5} md={3} className='animated animatedFadeInUp fadeInUp'>
                                        <Link to="#" onClick={(e) => {
                                            e.preventDefault();
                                            handlepath('new'); // Navigate programmatically if needed
                                        }}>
                                            <Card

                                                sx={{
                                                    borderRadius: '16px', // Adjust as needed
                                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adjust as needed
                                                    transition: '0.5s',
                                                    padding: 0.2,
                                                    '&:hover': {
                                                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Adjust as needed for hover effect
                                                    },
                                                }}>
                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={newPath}
                                                    alt="Paella dish"
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="span" className='text-sm lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center' component="div">
                                                        New Booking
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6} sm={3} lg={2} xl={1.5} md={3} className='animated animatedFadeInUp fadeInUp'>
                                        <Link to="#" onClick={(e) => {
                                            e.preventDefault();
                                            handlepath('login'); // Navigate programmatically if needed
                                        }}>
                                            <Card

                                                sx={{
                                                    borderRadius: '16px', // Adjust as needed
                                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adjust as needed
                                                    transition: '0.5s',
                                                    padding: 0.2,
                                                    '&:hover': {
                                                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Adjust as needed for hover effect
                                                    },
                                                }}>
                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={rebookPath}
                                                    alt="Paella dish"
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="span" className='text-sm lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center' component="div">
                                                        Re-schedule
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <br />
                                <Link to="/" >
                                    <button
                                        type='button'
                                        className="w-full mt-3  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    >
                                        Back to Home
                                    </button>
                                </Link>
                            </Box>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
