import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Label, Modal } from 'flowbite-react';
import { Link } from 'react-router-dom';
import FastingTest from './FastingTest';
export default function DataPrivacy({
    isPrivacyNotice,
    handleHideModal,
    isDataPrivacyChecked,
    isreadDataPrivacyChecked,
    isReturnPolicyChecked,
    isreadReturnPolicyChecked,
    setIsDataPrivacyChecked,
    setIsreadDataPrivacyChecked,
    setIsReturnPolicyChecked,
    setIsreadReturnPolicyChecked,
    apiEndPoint,
    ismobile,
    isFasting,
    setisFasting,
    setappointment_type,
    handleCheckeDataAgreement
}) {

    const [isreminders, setisreminders] = useState(false);
    const [isModalOpen1, setisModalOpen1] = useState(false);

    const [fastingList, setfastingList] = useState(false);
    const [fasting, setFasting] = useState(false);
    // Handle checkbox change
    const handleCheckboxChange = (setter, type) => (event) => {

        if (event.target.checked) {
            if (type === 'privacy') {
                setIsreadDataPrivacyChecked(true);
                setIsreadReturnPolicyChecked(false);
                openModal(type)
            }
            if (type === 'refund') {
                setIsreadReturnPolicyChecked(true);
                setIsreadDataPrivacyChecked(false);
                openModal(type)
            }
        }
        // setisreminders(event.target.checked)
        setter(event.target.checked);
    };
    const handleCheckboxChangeFasting = (setter, type) => (event) => {
        setisModalOpen1(event.target.checked)
        setter(event.target.checked);
        setisFasting(event.target.checked)
    }


    const closeReminders = () => {
        setisreminders(false)
    }

    // Function to open modal and set modal content based on card clicked
    const openModal = (type) => {
        // var contentdata = ''; 
        // var title = 'Out-patient Fast-Lane Arragement via CDUH`s On-line Portal';  
        if (type === 'privacy') {
            setIsreadDataPrivacyChecked(true);
            setIsreadReturnPolicyChecked(false);
            // contentdata = policyText
            // setIsModalOpen(true);
        }
        if (type === 'refund') {
            setIsreadReturnPolicyChecked(true);
            setIsreadDataPrivacyChecked(false);
            // contentdata = policyText
            // setIsModalOpen(true);
        }
        if (type === 'fasting') {
            // title = 'Laboratory Test Requires Fasting.';
            setisModalOpen1(true)
        }

    };

    const fetchCommitment = useCallback(async () => {
        try {
            const response = await fetch(`${apiEndPoint}get-patient-commitment`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            if (result) {
                // setDataPolicy(data.policy.description);
                // setDataRefund(data.patientrefundpolicy.description);
                setfastingList(result.exams);
                setappointment_type(result.registration_type);
            }
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint, setappointment_type]);


    // Policy text with <p> tags separating paragraphs
    const policyText = (
        <>
            <div className='mx-auto' style={{ height: '68vh' }}>
                <p className='whitespace-normal'>
                    Welcome to HappyDoc Diagnostics' Fast-Lane service!

                    Secure your spot for fast and convenient laboratory appointments. Book and pay with ease.

                    Please take note of the following information for your guidance. We are providing this notice to confirm the information you have supplied and recorded on CebuDoc's online portal.
                </p>

                <div className='mt-5 animated animatedFadeInUp fadeInUp'>
                    <label >We are printing out and serving a copy of this notice to confirm the following information you have supplied and recorded on CDUH’s on-line portal: </label>
                </div>
                <ol className="ps-5 mt-2 grid gap-2 space-y-1 list-decimal list-inside text-wrap ">
                    <li className='mb-3 animated animatedFadeInUp fadeInUp'>
                        &nbsp; &nbsp; The foregoing services availed (medicine, medical supplies, and for performance of blood and other laboratory tests and procedures are covered by a prescription issued by your physician on the required laboratory procedure.
                    </li>
                    <li className='mb-3 animated animatedFadeInUp fadeInUp'>
                        &nbsp; &nbsp;You have personally selected the date and time for the Fast-Lane facility, which has been confirmed through our online portal, text, email, or call.
                    </li>
                    <li className='mb-3 animated animatedFadeInUp fadeInUp'>
                        &nbsp; &nbsp;  As required, payment of the calculated fees must be made through online bank deposit or transfer. Proof of receipt will be confirmed at our end via the online portal, or through text, email, or call.
                    </li>

                    <li className='mb-3 animated animatedFadeInUp fadeInUp'>
                        &nbsp; &nbsp;Please remember that your scheduled Fast Lane appointment is reserved exclusively for you at a specific time, date, and place, and cannot be altered or changed. The reason for non-appearance and non-compliance must be communicated by paying a rebooking fee of 300 pesos.
                    </li>

                    <li className='mb-3 animated animatedFadeInUp fadeInUp'>
                        &nbsp; &nbsp;We have given instructions and you confirmed that you may be allowed to observe the required fasting for accuracy on the result. If required, please proceed to our Fast-Lane facility at least two (2) hours before the expiration of your fasting.
                    </li>
                    <li className='mb-3 animated animatedFadeInUp fadeInUp'>
                        &nbsp; &nbsp; Official results will be sent to your email address, or a copy made available online.
                    </li>
                </ol>
                <div className='mt-3 mb-3 animated animatedFadeInUp fadeInUp'>
                    <label >
                        [Confidentiality: Personal information and data supplied shall be treated as confidential. No disclosure is allowed unless written consent has been given (R.A. # 10173 or Data Privacy Act of 2012). CDUH and the physician allow reproduction of copies of medical records, billing statements, and receipts upon written request and shall be directly released to the client/patient upon payment of photocopying cost].
                    </label>
                </div>
            </div>
        </>
    );
    useEffect(() => {
        fetchCommitment();
    }, [fetchCommitment]);
    return (
        <div>
            {
                isPrivacyNotice && (
                    <div className="modal animated animatedFadeInUp fadeInUp">
                        <div className="modal-content h-screen">
                            <h1 className="text-xl text-left">Out-patient Fast-Lane Arragement via CDUH's On-line Portal</h1>
                            <div className="border border-b mt-2 border-gray-200"></div>
                            <div className="modal-section ">
                                <div >
                                    <div className="overflow-y-auto p-0 border-r-2  mb-4">
                                        <div className='p-0 border-none' >
                                            {policyText}
                                        </div>
                                    </div>
                                </div>
                                <div className="border border-b mt-2 border-gray-200"></div>
                                <div className="checkbox-container">
                                    <div className="flex items-center space-x-2">
                                        <Checkbox
                                            id="dataPrivacy"
                                            checked={isDataPrivacyChecked}
                                            onChange={handleCheckboxChange(setIsDataPrivacyChecked, 'privacy')}
                                        />
                                        <Label htmlFor="dataPrivacy">I agree to the Out-Patient Arrangement</Label>
                                    </div>
                                </div>
                                <div className="checkbox-container">
                                    <div className="flex items-center space-x-2">
                                        <Checkbox
                                            id="datafasting"
                                            checked={fasting}
                                            onChange={handleCheckboxChangeFasting(setFasting, 'fasting')}
                                        />
                                        <Label htmlFor="datafasting">Laboratory Test Requires Fasting.</Label>
                                    </div>
                                </div>
                            </div>
                            <div className="border border-b mt-2 mb-3 border-gray-200"></div>
                            <p className="modal-text">Please review the conditions and check the boxes that may apply to you</p>

                            {/*  */}
                            <div className="modal-footer flex mt-2 justify-between">
                                <Link to="/">
                                    <Button className="sm bg-red-500">
                                        Back
                                    </Button>
                                </Link>
                                <Button onClick={handleHideModal} disabled={!isDataPrivacyChecked} className="sm"  >
                                    Proceed
                                </Button>
                            </div>
                        </div>
                    </div>
                )
            }

            {isModalOpen1 && (
                <FastingTest isModalOpen1={isModalOpen1} setisModalOpen1={setisModalOpen1} fastingList={fastingList} />
            )}
            {isreminders && (
                <Modal show={true} onClose={closeReminders}>
                    <Modal.Body>
                        <h2 className="font-semibold ">Reminders</h2>
                        <div className="border border-b mt-2 border-gray-200"></div>
                        <div className="overflow-y-auto">
                            <div className="relative overflow-x-auto">
                                <p className='mt-3'>Please ensure you select the appropriate tests before proceeding with the payment. Your careful consideration will ensure the accuracy of your booking.</p>
                                <p className='mt-3'>For verification of unfamiliar tests, contact us at (032) 255-5555, local 705/706, or 09696157185, or email happydocdiagnostics.cdg@gmail.com.</p>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className='flex justify-end'>
                        <Button className="sm" onClick={closeReminders}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

        </div>
    );
}
