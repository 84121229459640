// src/data/navLinks.js
const navLinks = [
  { description:'Home', name: 'Home', image: '../booking-calendar.png', path: '/' },
  { description:'Patient results', name: 'ONLINE RESULTS', image: '../result.png', path: '/online-results' },
  { description:'Online Booking Services', name: 'ONLINE BOOKING', image: '../booking-calendar.png', path: '/online-booking' },
  { description:'See list of our doctors', name: 'OUR DOCTORS', image: '../doctors.png', path: '/our-doctors' },
  { description:'User guide', name: 'USER GUIDE', image: '../user-guide.png', path: '/users-guide' },
  { description:'User guide', name: 'OUR LOCATION', image: '../map.png', path: '/location' },
];

export default navLinks;
