import { useEffect, useState } from "react";
import { FaHome } from 'react-icons/fa';

import NavbarSidebarLayout from '../../../layouts/NavbarSidebarLayout';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Table,
  Modal,
  Label,
  TextInput,
} from "flowbite-react";
import { Link } from "react-router-dom";
// import _ from 'lodash';


export default function Information() {
 
  const [userDetails, setuserDetails] = useState({});
  const [isOpen, setOpen] = useState(false);
  const editDetails = async (item) => {
    setOpen(true)
  };

  const fetchPatientDetails = async (value) => {

    const userData = localStorage.getItem('user');
    if (userData) {
      try {
        // Parse the user data if it's a JSON string
        setuserDetails(JSON.parse(userData));
      } catch (error) {
        console.error('Error parsing user data from local storage:', error);
      }
    } else {
      console.log('No user data found in local storage');
    }
  };

  useEffect(() => {
    fetchPatientDetails();
  }, []);


  // Handle pagination change
  return (

    <NavbarSidebarLayout>
      <div className="h-screen w-full">
        <div className="block items-center  w-full justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item as={Link} to="/appointment">
                  <div className="flex items-center gap-x-3">
                    <FaHome className="text-xl" />
                    <span className="dark:text-white">Patient</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item as={Link} to="/appointment">
                  Information
                </Breadcrumb.Item>
              </Breadcrumb>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Profile Information
              </h1>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800 ">
          <Table className="w-full" >
            <Table.Head>
              <Table.HeadCell className="p-4">
                <Checkbox />
              </Table.HeadCell>
              <Table.HeadCell>Patient Name</Table.HeadCell>
              <Table.HeadCell>Age</Table.HeadCell>
              <Table.HeadCell>Birth Date</Table.HeadCell>
              <Table.HeadCell>Sex </Table.HeadCell>
              <Table.HeadCell>Email Address</Table.HeadCell>
              <Table.HeadCell>Contact No.</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>Address</Table.HeadCell>
              <Table.HeadCell></Table.HeadCell>

            </Table.Head>
            <Table.Body className="divide-y">
              <Table.Row key={userDetails.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="p-4">
                  <Checkbox />
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{userDetails.lastname}{','} {userDetails.firstname} {userDetails.middlename}</Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{userDetails.age}</Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{userDetails.birthdate}</Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{userDetails.sexs ? userDetails.sexs.description : ''}</Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{userDetails.email}</Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{userDetails.mobile}</Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{userDetails.process_by ? 'Paid' : 'Pending'}</Table.Cell>
                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{userDetails.birthplace}</Table.Cell>
                <Table.Cell>
                  <Button onClick={() => editDetails(userDetails)} small className="font-medium  hover:underline dark:text-cyan-500">
                    {'View'}
                  </Button>
                </Table.Cell>
              </Table.Row>
              {/* ))} */}
            </Table.Body>
          </Table>
          {/* <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(filteredItems.length / pageSize)}
            onPageChange={handlePageChange}
          /> */}
        </div>
        <Modal onClose={() => setOpen(false)} show={isOpen}>
          <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
            <strong>Patient Information</strong>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="grid grid-cols-1 gap-3 mb-2 lg:grid-cols-3">
                <div>
                  <Label htmlFor="Lastname">Lastname</Label>
                  <TextInput
                    id="Lastname"
                    readOnly
                    value={userDetails.lastname}
                    className="mt-1"
                  />
                </div>
                <div>
                  <Label htmlFor="Firstname">Firstname</Label>
                  <TextInput
                    id="Firstname"
                    readOnly
                    value={userDetails.firstname}
                    className="mt-1"
                  />
                </div>
                <div>
                  <Label htmlFor="Middlename">Middlename</Label>
                  <TextInput
                    id="Middlename"
                    readOnly
                    value={userDetails.middlename}
                    className="mt-1"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-3 mb-2 lg:grid-cols-3">
                <div>
                  <Label htmlFor="Birthdate">Birthdate</Label>
                  <TextInput
                    type="date"
                    readOnly
                    id="Birthdate"
                    value={userDetails.birthdate}
                    className="mt-1"
                  />
                </div>
                <div>
                  <Label htmlFor="Age">Age</Label>
                  <TextInput
                    id="Age"
                    readOnly
                    value={userDetails.age}
                    className="mt-1"
                  />
                </div>
                <div>
                  <Label htmlFor="sex">Sex</Label>
                  <TextInput
                    id="sex"
                    readOnly
                    value={userDetails.sexs ? userDetails.sexs.description : ''}
                    className="mt-1"
                  />
                </div>

              </div>
              <div className="grid grid-cols-1 gap-3 mb-2 lg:grid-cols-3">
                <div className="col-span-2">
                  <Label htmlFor="Email">Email</Label>
                  <TextInput
                    id="Email"
                    readOnly
                    value={userDetails.email}
                    className="mt-1"
                  />
                </div>
                <div>
                  <Label htmlFor="Contact">Contact No.</Label>
                  <TextInput
                    id="Contact"
                    value={userDetails.mobile}
                    className="mt-1"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-3 mb-2 lg:grid-cols-3">
                <div className="col-span-3">
                  <Label htmlFor="Address">Address</Label>
                  <TextInput
                    id="Address"
                    readOnly
                    value={userDetails.streetbldg}
                    className="mt-1"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-3 mb-2 lg:grid-cols-1">
                <div className="col-span-3">
                  <Label htmlFor="Address">Doctor Name</Label>
                  <TextInput
                    id="Address"
                    readOnly
                    value={userDetails.doctor_name}
                    className="mt-1"
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="flex justify-end">
            <Button onClick={() => setOpen(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </NavbarSidebarLayout>
  );
}
