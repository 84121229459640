import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import AppointmentCard from './Card/AppointmentCard';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { format, formatDate } from 'date-fns';
import Modal from '@mui/material/Modal';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import Skeleton from '@mui/material/Skeleton';
const AppointmentContent = () => {

    const [errors, setErrors] = useState({});
    const apiEndPoint = process.env.REACT_APP_API_URL;
    const [itemlist, setitemlist] = useState([]);
    const [filter, setFilter] = useState('');
    const [filterReference, setfilterReference] = useState('');
    const [filterType, setfilterType] = useState('');
    const [paymentTypeList, setpaymentTypeList] = useState([]);
    const [text, setText] = useState('');
    const [isFullBook, setisFullBook] = useState(false);
    const [openChangeModal, setOpenChangeModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // Number of items per page
    // Static details for patient
    const [formData, setFormData] = useState({
        payment_type_id: '',
        payment_type: '',
        payment_fee: 300,
        payment_reference: '',
        payment_file: null,
        payment_accountno: '',
        patient_id: '',
        schedule_date: '',
    });
   

    // Static details for appointment
    const appointmentDetails = {
        dateSchedule: '2024-08-15', // Format: YYYY-MM-DD
        referenceNo: 'ABC123DEF456',
        doctorsName: 'Dr. Jane Smith',
        appointmentType: 'Fast-lane',
    };

    // State variables for filters
    const [filterDateSchedule, setFilterDateSchedule] = useState('');
    const [filterReferenceNo, setFilterReferenceNo] = useState('');

    // Modal state
    const [openModal, setOpenModal] = useState(false);

    const paymentTypeListoptions = paymentTypeList.map(item => ({
        value: parseInt(item.id), // Define the value for each option
        label: item.name, // Define the label (display text) for each option
        account: item.account
    }));
    const itemListoptions = itemlist.map(item => ({
        ...item,
        id: item.patient_id, // Define the value for each option
        contact: item.mobile, // Define the value for each option
        sex: item.sex.description, // Define the value for each option
        birthdate: format(item.birthdate, 'yyyy-MM-dd'), // Define the value for each option
        name: item.patient_name, // Define the value for each option
        date_schedule: format(item.date_schedule, 'yyyy-MM-dd'), // Define the value for each optionregistration_type
        registration_type: item.registration_type.name, // Define the value for each optionregistration_type
        amount: parseFloat(item.total_amount) + Math.max(item.rebook_fee), // Define the value for each option
        penalty: item.penalty, // Define the value for each option
        payment_type: item.paymenttype.name, // Define the value for each option
        payment_type_id: item.paymenttype.id, // Define the value for each option
        rebook_fee: Math.max(item.rebook_fee), // Define the value for each option
        process_by: item.process_by, // Define the value for each option
        items: item.cash_charges, // Define the value for each option
        charges_fee: Math.max(item.total_amount) - Math.max(item.rate_amount), // Define the value for each option
        convenience_fee: item.rate_amount, // Define the value for each option
        total_fee: item.total_amount, // Define the value for each option
    }));
    const fetchProcedureList = useCallback(async () => {
        try {
            const userData = JSON.parse(localStorage.getItem('user'));
            if (userData) {
                const response = await fetch(`${apiEndPoint}get-appointment-list?patient_id=` + userData.patient_id);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                if (data) {
                    setitemlist(data.data.list)
                }
            }
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint]);

    const fetchPaymentTypeList = useCallback(async () => {
        try {
            const response = await fetch(`${apiEndPoint}get-payment-type`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            setpaymentTypeList(result.data)
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint]);

    const filteredItems = itemListoptions.filter(item => {
        if (filterType === 'reference') {
            return item.reference_no.toLowerCase().includes(filterReference.toLowerCase()) ||
                item.doctor_name.toLowerCase().includes(filterReference.toLowerCase());
        } else if (filterType === 'date') {
            return item.date_schedule.toLowerCase().includes(filter.toLowerCase())
        } else {
            return true;
        }
    }
    );

    const paginate = (array, page_size, page_number) => {
        const paginatedArray = _.chunk(array, page_size);
        return paginatedArray[page_number - 1] || [];
    };

    const paginatedItems = paginate(filteredItems, pageSize, currentPage);

    // Handle change in filters
    const handleDateScheduleChange = (event) => {
        setFilterDateSchedule(event.target.value);
    };

    const handleReferenceNoChange = (event) => {
        setFilterReferenceNo(event.target.value);
    };

    // Filter logic - replace with your filtering logic based on your requirements
    const filteredAppointments = (filterDateSchedule || filterReferenceNo)
        ? [appointmentDetails] // Replace with your actual filtering logic
        : [appointmentDetails];

    // Open modal
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    // Close modal
    const handleCloseModal = () => {
        setOpenModal(false);
        setFilterDateSchedule(''); // Reset filter values if needed
        setFilterReferenceNo('');
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Example: You can perform actions like sending data to a server here
        console.log('Form data submitted:', formData);

        if(!formData.payment_fee || !formData.payment_accountno || !formData.payment_file || !formData.payment_reference || !formData.schedule_date) {
            return;
        }; 
        const FormPayload = new FormData();

        // Iterate through formData and append each key-value pair to FormData
        
        for (const key in formData) {
            console.log(key,'333')
            if (key === 'payment_file') {
                // Append the File object directly
                FormPayload.append('payment_file', formData.payment_file);
            } else {
                // Append other fields normally
                FormPayload.append(key, formData[key]);
            }
        }
        const response = await fetch(`${apiEndPoint}reschedule`, {
            method: 'POST', // Assuming this is a POST request based on your previous examples
            // headers: {
            //     'Content-Type': 'application/json', // Adjust content type as needed
            // },
                body: FormPayload
            }
        );
        const result = await response.json();
        if (result.data) {
            fetchProcedureList();
            setOpenChangeModal(false);
            setText('Successfully Changed')
            setisFullBook(true)
        }
      
        setFormData({
            payment_type_id: '',
            payment_type: '',
            payment_fee: 300,
            payment_reference: '',
            payment_file: '',
            payment_accountno: '',
            patient_id: '',
            schedule_date: '',
        });
    };
    useEffect(() => {
        fetchProcedureList();
        fetchPaymentTypeList();
    }, [fetchPaymentTypeList, fetchProcedureList]);
    return (
        <Box>
            {/* Breadcrumbs */}
            <Paper elevation={0} className='mb-3'>
                <Grid container spacing={2} alignItems="center" alignContent="space-between">
                    <Grid item xs={8} md={10}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography color="textPrimary">LIST OF APPOINTMENTS</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={2} textAlign="right">
                    {/* onClick={handleOpenModal} */}
                        <Button  aria-label="filter appointments">
                            <FilterListIcon /> Search
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Divider style={{ marginBottom: 5 }} />
            {/* Modal for Filters */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" id="modal-modal-title" gutterBottom>
                        Filter Appointments
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            label="Date Schedule"
                            type='date'
                            variant="outlined"
                            value={filterDateSchedule}
                            onChange={handleDateScheduleChange}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Reference No"
                            variant="outlined"
                            value={filterReferenceNo}
                            onChange={handleReferenceNoChange}
                            sx={{ mb: 2 }}
                        />
                        <Button onClick={handleCloseModal} variant="contained" color="primary">
                            Apply Filters
                        </Button>
                        <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Display filtered appointments */}
            {itemlist.length === 0 ? (
                <Box sx={{ width: 300 }}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                </Box>
            ) : (
                <Grid container spacing={0}>
                    {paginatedItems.map((appointment, index) => (
                        <AppointmentCard appointment={appointment} 
                        paymentlist={paymentTypeListoptions} formData={formData}
                        setFormData={setFormData} handleSubmit={handleSubmit} 
                        openChangeModal={openChangeModal} 
                        setOpenChangeModal={setOpenChangeModal} 
                        text={text} 
                        setText={setText} 
                        isFullBook={isFullBook} 
                        setisFullBook={setisFullBook} 
                        />
                    ))}
                </Grid>
            )
            }
        </Box>
    );
};

export default AppointmentContent;
