import React, { useEffect, useCallback, useState, useRef } from 'react'

import { FaUser, FaCreditCard, FaCalendar, FaBackward, FaFastForward, FaCheckCircle } from 'react-icons/fa';
import { Button, Modal, Card } from "flowbite-react";
import BasicInfo from './Forms/BasicInfo';
import Appointment from './Forms/Appointment';
import DataPrivacy from './Forms/DataPrivacy';
import Payment from './Forms/Payment';
import Complete from './Forms/Complete';
import PatientOption from './Forms/PatientOption';

export default function Booking() {

    const apiEndPoint = process.env.REACT_APP_API_URL;
    const [currentStep, setCurrentStep] = useState(0);
    const [IsPatientOption, SetIsPatientOption] = useState(true);
    const [isPrivacyNotice, setisPrivacyNotice] = useState(false);


    // State for checkboxes
    const [isDataPrivacyChecked, setIsDataPrivacyChecked] = useState(false);
    const [isFasting, setisFasting] = useState(false);
    const [isreadDataPrivacyChecked, setIsreadDataPrivacyChecked] = useState(false);
    const [isReturnPolicyChecked, setIsReturnPolicyChecked] = useState(false);
    const [isreadReturnPolicyChecked, setIsreadReturnPolicyChecked] = useState(false);

    const [showModal, setShowModal] = useState(false);
    // const [categoryList, setcategoryList] = useState([]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filter, setFilter] = useState('');
    const [items, setitemsList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [doctorList, setdoctorList] = useState([]);
    const [paymentTypeList, setpaymentTypeList] = useState([]);
    const [bookingresult, setbookingresult] = useState({});

    const [loading, setLoading] = useState(true);
    const [issubmitloading, setissubmitloading] = useState(true);
    const [appointment_typelist, setappointment_type] = useState([]);
    const itemListoptions = items.map(item => ({
        id: item.meditemid, // Define the value for each option
        name: item.itemdesc, // Define the value for each option
        amount: item.get_price.price, // Define the label (display text) for each option
    }));

    const appointment_type = appointment_typelist.map(item => ({
        value: item.id, // Define the value for each option
        label: item.name, // Define the value for each option
    }));


    const filteredItems = itemListoptions.filter(item =>
        item.name.toLowerCase().includes(filter.toLowerCase())
    );
    const categoryList = [
        { value: '1', label: 'Regular' },
        { value: '2', label: 'Senior' },
        { value: '3', label: 'PWD' }
    ];

    const initialRefs = {
        lastname: useRef(''),
        firstname: useRef(''),
        birthdate: useRef(''),
        sex: useRef(''),
        category_id: useRef(''),
        senior_id: useRef(''),
        civilStatus: useRef(''),
        nationality: useRef(''),
        religion: useRef(''),
        mobile: useRef(''),
        email: useRef(''),
        birthplace: useRef(''),
        streetbldg: useRef(''),
        zipcode: useRef(''),
        province_id: useRef(''),
        province_name: useRef(''),
        municipality_id: useRef(''),
        municipality_name: useRef(''),
        barangay: useRef(''),
        region_code: useRef(''),
    };

    const initialRefsAppointment = {
        booking_type: useRef(''),
        booking_type_name: useRef(''),
        booking_date: useRef(''),
        booking_doctor: useRef(''),
        booking_doctor_name: useRef(''),
    };

    const initialReference = {
        referenceno: useRef('')
    }

    const [errorsAppointment, setErrorsAppointment] = useState({});
    // basic information 
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        lastname: '',
        firstname: '',
        middlename: '',
        birthdate: '',
        age: 0,
        sex: '',
        civilStatus: '',
        category_id: 1,
        senior_id: '',
        nationality: '',
        religion: '',
        mobile: '',
        telno: '',
        email: '',
        birthplace: '',
        streetbldg: '',
        zipcode: '',
        region_id: '',
        province_name: '',
        province_id: '',
        municipality_name: '',
        municipality_id: '',
        barangay: '',
        region_code: ''
    });
    // appointment info
    const [formDataAppointment, setFormDataAppointment] = useState({
        booking_type: '',
        booking_date: '',
        booking_doctor: '',
        booking_doctor_name: '',
        booking_type_name: '',
        image: '',
    });

    // fee info 
    const [formFee, setFormFeeData] = useState({
        totalChargesFee: 0,
        totalConvenienceFee: 0,
        totalFeeSenior: 0,
        totalSubfee: 0,
        totalFee: 0,
        payment_account: '',
        payment_type: '',
        payment_reference: '',
        payment_amount: 0,
        payment_type_name: '',
        payment_attachment: ''
    });
    const refs = { ...initialRefs }; // Spread initial refs into component refs
    const refsAppointment = { ...initialRefsAppointment }; // Spread initial refs into component refs
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });

    };
    const handleChangeSenior = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            senior_id: value
        });

    };
    const handleChangecategory = (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            category_id: value
        });
    };


    const handleChangeAppointment = (e) => {
        const { id, value } = e.target;
        setFormDataAppointment({
            ...formDataAppointment,
            [id]: value
        });
    };
    const validate = () => {
        const newErrors = {};
        let firstError = null;
        console.log(newErrors, 'qwqw');
        if ((Math.max(formData.age) >= 59 || Math.max(formData.category_id) !== 1) && formData.senior_id === '') {
            newErrors.senior_id = 'This field is required ';
            firstError = 'senior_id';
        }
        Object.keys(formData).forEach((key) => {
            if (!formData[key] && key !== 'senior_id') {
                if (refs[key]) {
                    newErrors[key] = 'This field is required';
                    if (!firstError && refs[key] && refs[key].current) {
                        firstError = key;
                    }
                }
            }
        });
        setErrors(newErrors);
        if (firstError && refs[firstError] && refs[firstError].current) {
            refs[firstError].current.focus();
        }
        console.log(newErrors, 'qwqw');
        return Object.keys(newErrors).length === 0;
    };
    const validateAppointment = () => {
        const newErrors = {};
        let firstError = null;
        Object.keys(formDataAppointment).forEach((key) => {
            if (!formDataAppointment[key]) {

                if (refsAppointment[key]) {
                    newErrors[key] = 'This field is required';
                    if (!firstError && refsAppointment[key] && refsAppointment[key].current) {
                        firstError = key;
                    }
                }

            }
        });
        setErrorsAppointment(newErrors);

        if (firstError && refsAppointment[firstError] && refsAppointment[firstError].current) {
            refsAppointment[firstError].current.focus();
        }

        console.log(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // You can perform further actions like submitting the data to an API here
        if (validate() && validateAppointment() && formFee.payment_type && formFee.payment_reference && formFee.payment_attachment) {
            setissubmitloading(false)
            // Handle form submission
            let patientdata = { ...formData };
            patientdata.patientname = patientdata.lastname + ', ' + patientdata.firstname + ' ' + patientdata.middlename;
            patientdata.paid_amount = formFee.totalFee;
            patientdata.discount = formFee.totalFeeSenior;
            patientdata.subtotal = formFee.totalSubfee;
            patientdata.total_charges = formFee.totalChargesFee;
            patientdata.total_fee = formFee.totalFee;
            patientdata.total_conveneince_fee = formFee.totalConvenienceFee;
            patientdata.total_amount = formFee.payment_amount;
            patientdata.payment_type_name = formFee.payment_type_name;
            patientdata.drivethru_rate = formFee.totalConvenienceFee;
            patientdata.payment_type = formFee.payment_type;
            patientdata.reference_no = formFee.payment_reference;
            patientdata.senior_id = formData.category_id = 2 ? formData.senior_id : '';
            patientdata.date_schedule = formDataAppointment.booking_date;
            patientdata.doctor_code = formDataAppointment.booking_doctor;
            patientdata.doctor_name = formDataAppointment.booking_doctor_name;
            patientdata.registration_type = formDataAppointment.booking_type;
            patientdata.registration_type_name = formDataAppointment.booking_type_name;

            patientdata.type = '0';
            patientdata.transdate = '';
            patientdata.ehrPatientID = '';
            patientdata.ehrPatientID = '';
            patientdata.isPrivacyNotice = isPrivacyNotice ? false : true;

            const FormPayload = new FormData();

            FormPayload.append('examCart', JSON.stringify(selectedItems));
            // Append patient data
            for (const key in patientdata) {
                FormPayload.append(key, patientdata[key]);
            }
            if (formDataAppointment.image) { // Ensure `formDataAppointment.image` is defined in your state
                FormPayload.append('image', formDataAppointment.image);
            }

            if (formFee.payment_attachment) { // Ensure `formDataAppointment.image` is defined in your state
                FormPayload.append('payment_attachment', formFee.payment_attachment);
            }
            const response = await fetch(`${apiEndPoint}submit-online-registration`,
                {
                    method: 'POST', // Assuming this is a POST request based on your previous examples
                    // headers: {
                    //     'Content-Type': 'application/json', // Adjust content type as needed
                    //     // Add other headers if required
                    // },
                    body: FormPayload

                }
            );
            const result = await response.json();

            if (result) {
                patientdata.reference_data = result.data.reference_no;
                patientdata.id = result.data.patient_id;
                patientdata.username = result.data.username;
                patientdata.password = result.data.temp_password;
                setissubmitloading(true)
                setShowModal(true);
                setTimeout(() => {
                    setShowModal(false);
                }, 3000)
                setbookingresult(patientdata);
                setCurrentStep(currentStep + 1);
            }

            console.log('Form submitted 123:', patientdata);
            console.log('Form1 submitted:', formDataAppointment);
            console.log('Form2 submitted:', formFee);
            console.log('Form23 submitted:', selectedItems);
        }
    };
    const handleSubmitAppointment = (e) => {
        e.preventDefault();
        // You can perform further actions like submitting the data to an API here
        if (validate()) {
            // Handle form submission
            console.log('Form submitted:', formDataAppointment);
        }
    };


    const handleCheckboxChange = (item) => {
        if (selectedItems.some((selected) => selected.id === item.id)) {
            setSelectedItems(selectedItems.filter((selected) => selected.id !== item.id));
        } else {
            setSelectedItems([...selectedItems, item]);
        }

    };

    const handleCheckeDataAgreement = () => {
        setIsDataPrivacyChecked(false)

    };
    const handleSelectedTest = () => {
        calculateFee();
        setIsModalOpen(false)
    };
    // let discountPercentage = 20;
    // let discountRate = discountPercentage / 100;
    // let discountamount = amount * discountRate;
    const totalAmount = selectedItems.reduce((accumulator, item) => accumulator + parseFloat(item.amount), 0);

    const handleRemoveItem = (detail) => {
        const updatedItems = selectedItems.filter((item) => item.id !== detail.id);
        setSelectedItems(updatedItems); // Update state with the modified array
        console.log(updatedItems)
        const amount = updatedItems.reduce((accumulator, item) => accumulator + parseFloat(item.amount), 0);

        let discountPercentage = 20;
        let discountRate = discountPercentage / 100;
        let discountamount = amount * discountRate;
        if (formData.category_id == 1) {
            discountamount = 0;
        }
        setFormFeeData({
            ...formFee,
            totalFeeSenior: Math.max(discountamount),
            totalSubfee: (Math.max(amount) - Math.max(discountamount)),
            totalChargesFee: Math.max(amount),
            totalConvenienceFee: Math.max(formFee.totalConvenienceFee),
            totalFee: (Math.max(amount) - Math.max(discountamount)) + Math.max(formFee.totalConvenienceFee),
            payment_amount: (Math.max(amount) - Math.max(discountamount)) + Math.max(formFee.totalConvenienceFee)
        })


    };

    const formatNumber = (amount) => {
        if (!amount) return Math.max(0);
        return amount.toLocaleString('en-PH', {
            style: 'currency',
            currency: 'PHP',
        });
    }
    const calculateFee = () => {
        let discountPercentage = 20;
        let discountRate = discountPercentage / 100;

        let discountamount = totalAmount * discountRate;
        if (formData.category_id == 1) {
            discountamount = 0;
        }
        setFormFeeData({
            ...formFee,
            totalFeeSenior: Math.max(discountamount),
            totalSubfee: (Math.max(totalAmount) - Math.max(discountamount)),
            totalChargesFee: Math.max(totalAmount),
            totalConvenienceFee: Math.max(formFee.totalConvenienceFee),
            totalFee: (Math.max(totalAmount) - Math.max(discountamount)) + Math.max(formFee.totalConvenienceFee),
            payment_amount: (Math.max(totalAmount) - Math.max(discountamount)) + Math.max(formFee.totalConvenienceFee)
        })
    }

    const fetchDoctorList = async () => {
        if (doctorList.length > 0) return;
        try {
            const response = await fetch(`${apiEndPoint}get-msc-doctors`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setdoctorList(data.data)
        } catch (error) {
        } finally {
        }
    };

    const fetchExamList = async () => {

        if (items.length > 0) return;
        try {
            setLoading(true)
            const response = await fetch(`${apiEndPoint}get-msc-examination`,
                {
                    method: 'POST', // Assuming this is a POST request based on your previous examples
                    headers: {
                        'Content-Type': 'application/json', // Adjust content type as needed
                        // Add other headers if required
                    },
                    body: JSON.stringify({
                        item: [],
                    })
                }
            );
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            if (data) {

                setLoading(false)
                setitemsList(data.data)
            }
        } catch (error) {
        } finally {
        }
    };

    const fetchAppointmentTypeList = async (value) => {

        try {
            setFormFeeData({
                ...formFee,
                totalFee: 0,
            })
            const response = await fetch(`${apiEndPoint}get-drive-thru-rate?registration_type=` + value,

            );
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            setFormFeeData({
                ...formFee,
                totalChargesFee: parseFloat(totalAmount),
                totalConvenienceFee: parseFloat(result.data.amount),
                totalFee: parseFloat(totalAmount) + parseFloat(result.data.amount),
            })

        } catch (error) {
        } finally {
        }
    };

    const fetchPaymentTypeList = useCallback(async () => {
        try {
            const response = await fetch(`${apiEndPoint}get-payment-type`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const result = await response.json();
            setpaymentTypeList(result.data);
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint]);

    useEffect(() => {
        fetchPaymentTypeList();
        const handleResize = () => {
            if (window.innerWidth <= 640) {
                setIsMobile(true); // Adjust as per your mobile breakpoint
            } else {
                setIsMobile(false); // Adjust as per your mobile breakpoint
            }
        };

        // Initial check on mount
        handleResize();

        // Listen to window resize events
        window.addEventListener('resize', handleResize);

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fetchPaymentTypeList]);

    const handleSelectItem = () => {
        setIsModalOpen(true)
        fetchExamList();
    }

    const handlePaymentTypeChange = selectedOption => {
        setFormFeeData({
            ...formFee,
            payment_type: parseInt(selectedOption.value), // Update zipcode in formData with the selected option's 
            payment_account: selectedOption.account, // Update zipcode in formData with the selected option's 
            payment_type_name: selectedOption.label, // Update zipcode in formData with the selected option's 
        });
    }

    const handlePaymentChange = (e) => {
        const { value } = e.target;
        setFormFeeData({
            ...formFee,
            payment_reference: value
        });
    }

    const handleSubmitFee = (e) => {
        e.preventDefault();
    }

    const handleNext = () => {
        if (currentStep === 0) {
            if (!validate()) return;
        }
        if (currentStep === 1) {
            if (!validateAppointment()) return;
            if (selectedItems.length === 0) return;
        }
        if (currentStep === 2) {
            if (!formFee.payment_reference) {
                return;
            }
        }
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }

        console.log(validate())

        if (currentStep === 0) {
            console.log(validate())
            fetchDoctorList()
        }

    };

    const steps = [
        {
            label: 'Fill out Patient details', icon: FaUser, component: <BasicInfo
                errors={errors}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                refs={refs}
                setFormData={setFormData}
                apiEndPoint={apiEndPoint}
                categoryList={categoryList}
                handleChangeSenior={handleChangeSenior}
                handleChangecategory={handleChangecategory}
            />
        },
        {
            label: 'Schedule an appointment', icon: FaCalendar, component: <Appointment
                appointment_type={appointment_type}

                isModalOpen={isModalOpen}
                handleCheckboxChange={handleCheckboxChange}
                handleSelectedTest={handleSelectedTest}
                filteredItems={filteredItems}
                handleRemoveItem={handleRemoveItem}
                setIsModalOpen={setIsModalOpen}
                filter={filter}
                setFilter={setFilter}
                selectedItems={selectedItems}
                formatNumber={formatNumber}
                doctorList={doctorList}
                handleSelectItem={handleSelectItem}
                itemListoptions={itemListoptions}

                errors={errorsAppointment}
                formData={formDataAppointment}
                handleChange={handleChangeAppointment}
                handleSubmit={handleSubmitAppointment}
                refs={refsAppointment}
                setFormData={setFormDataAppointment}
                loading={loading}
                apiEndPoint={apiEndPoint}
                fetchAppointmentTypeList={fetchAppointmentTypeList}

            />
        },
        {
            label: 'Submit Payment', icon: FaCreditCard, component: <Payment
                formFee={formFee}
                paymentTypeList={paymentTypeList}
                handlePaymentTypeChange={handlePaymentTypeChange}
                formatNumber={formatNumber}
                handlePaymentChange={handlePaymentChange}
                initialReference={initialReference}
                handleSubmitFee={handleSubmitFee}
                apiEndPoint={apiEndPoint}
                setFormFeeData={setFormFeeData}
            />
        },
        {
            label: 'Submit the request for approval', icon: FaCheckCircle, component: <Complete bookingresult={bookingresult}
                formatNumber={formatNumber} />
        },
    ];

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };
    const handleHideModal = () => {
        setisPrivacyNotice(false)
    };

    const handleSetPatientOpition = () => {
    }

    return (
        <div>
            <PatientOption
                IsPatientOption={IsPatientOption}
                handleSetPatientOpition={handleSetPatientOpition}
                SetIsPatientOption={SetIsPatientOption}
                setisPrivacyNotice={setisPrivacyNotice}
            />
            <DataPrivacy
                isPrivacyNotice={isPrivacyNotice}
                handleHideModal={handleHideModal}
                isDataPrivacyChecked={isDataPrivacyChecked}
                isreadDataPrivacyChecked={isreadDataPrivacyChecked}
                isReturnPolicyChecked={isReturnPolicyChecked}
                isreadReturnPolicyChecked={isreadReturnPolicyChecked}
                setIsDataPrivacyChecked={setIsDataPrivacyChecked}
                setIsreadDataPrivacyChecked={setIsreadDataPrivacyChecked}
                setIsReturnPolicyChecked={setIsReturnPolicyChecked}
                setIsreadReturnPolicyChecked={setIsreadReturnPolicyChecked}
                apiEndPoint={apiEndPoint}
                ismobile={isMobile}
                isFasting={isFasting}
                setisFasting={setisFasting}
                setappointment_type={setappointment_type}
                handleCheckeDataAgreement={handleCheckeDataAgreement}
            />

            <div className={`${isMobile ? 'md:p-4 lg:p-4 max-h-full' : 'p-4 md:p-4 lg:p-4 max-h-full'}`}>
                <ol style={{ border: 0 }} className="rounded-none border-none shadow-none flex items-center w-full p-4 space-x-8 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200  dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                    {steps.map((step, index) => (
                        <li key={index} className={`flex items-center ${index <= currentStep ? 'text-blue-600 dark:text-blue-500' : ''}`}>
                            <span className={`flex items-center justify-center w-8 h-8 me-2 text-xs border rounded-full ${index <= currentStep ? 'border-blue-600 dark:border-blue-500' : 'border-gray-500 dark:border-gray-400'}`}>
                                <step.icon className="w-5 h-5" />
                            </span>
                            {!isMobile && (
                                <span className="flex items-center">
                                    {step.label}
                                </span>
                            )}
                            {index < steps.length - 1 && (
                                <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4" />
                                </svg>
                            )}
                        </li>
                    ))}
                </ol>

                <Card style={{ border: 0 }} className='rounded-none shadow-none border-none'>
                    <div className="border border-b border-gray-200"></div>
                    {
                        (currentStep !== 2) ? (
                            <div>
                                <h2 className="font-semibold mb-3">Reminders</h2>
                                <div className="overflow-y-auto">
                                    <div className="relative overflow-x-auto">
                                        <p className='mt-1'>Please ensure you select the appropriate tests before proceeding with the payment. Your careful consideration will ensure the accuracy of your booking. For verification of unfamiliar tests, contact us at (032) 255-5555, local 705/706, or 09696157185, or email happydocdiagnostics.cdg@gmail.com.</p>
                                    </div>
                                </div>
                            </div>
                        ) : ('')
                    }
                    <div className="border border-b mt-2 mb-3 border-gray-200"></div>
                    <div>{steps[currentStep].component}</div>
                </Card>
                <div className="border border-b mt-2 border-gray-200"></div>
                <Card style={{ border: 0 }} className='rounded-none shadow-none border-none p-0'>
                    {(currentStep === 1 || currentStep === 2) ? (
                        <div>
                            <div>
                                <div className='flex justify-between items-center'>
                                    <h5 className='col mt-1' size="sm">Charges Fee:</h5>
                                    <h5 className='col mt-1' size="sm">{formatNumber(formFee.totalChargesFee)}</h5>
                                </div>

                                {formData.senior_id ? (
                                    <div className='flex justify-between items-center'>
                                        <h5 className='col mt-1' size="sm">Discount Fee:</h5>
                                        <h5 className='col mt-1' size="sm">{formatNumber(formFee.totalFeeSenior)}</h5>
                                    </div>
                                ) : ('')}
                                <div className='flex justify-between items-center'>
                                    <h5 className='col mt-1' size="sm">Sub Total Fee :</h5>
                                    <h5 className='col mt-1' size="sm">{formatNumber(formFee.totalSubfee)}</h5>
                                </div>
                                <div className='flex justify-between items-center'>
                                    <h5 className='col mt-1' size="sm">Convenience Fee :</h5>
                                    <h5 className='col mt-1' size="sm">{formatNumber(formFee.totalConvenienceFee)}</h5>
                                </div>
                                <div className='flex justify-between items-center'>
                                    <h5 className='col mt-1' size="sm">Total Amount :</h5>
                                    <h5 className='col mt-1' size="sm">{formatNumber(formFee.totalFee)}</h5>
                                </div>
                            </div>

                            <div className="border border-b mt-2 mb-3 border-gray-200"></div>
                        </div>
                    ) : ('')}
                    <div className="flex justify-between items-center">
                        {currentStep !== 3 && (<Button
                            onClick={handlePrevious}
                            disabled={currentStep === 0}
                            className="text-white bg-blue-500  disabled:bg-gray-400"
                        >
                            <FaBackward className='mt-1' />  &nbsp;  Previous
                        </Button>
                        )
                        }
                        {(currentStep === 2) && (
                            issubmitloading === true ? (
                                <Button
                                    onClick={handleSubmit}
                                    className="text-white bg-blue-500 "
                                >
                                    Submit

                                </Button>) : (
                                <Button
                                    className="text-white bg-blue-500 "
                                >
                                    Processing...

                                </Button>
                            )
                        )}
                        {((currentStep < steps.length - 1) && currentStep !== 2) && (
                            <Button
                                onClick={handleNext}
                                className=" text-white bg-blue-500  disabled:bg-gray-400"
                                disabled={currentStep === steps.length - 1}
                            >
                                Next &nbsp; <FaFastForward className='mt-1' />
                            </Button>
                        )}
                    </div>
                </Card>
            </div >

            <Modal show={showModal} onClose={() => setShowModal(false)} position="center" size="md" popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <FaCheckCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            Your booking has been successfully submitted!
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button onClick={() => setShowModal(false)} >
                                {"Close"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}
