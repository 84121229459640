// src/components/Navbar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import navLinks from '../../data/navBarMenu'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Navbar } from 'flowbite-react';

const NavbarLink = ({ hideNavbar }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // Check if the current route is '/login'
  const isLoginRoute = location.pathname.includes('portal');

  // If it's the login route, do not render the navbar
  if (isLoginRoute) {
    return null;
  }

  return (
    <Navbar fluid={true} rounded={true} className='border-b-2 p-5 border-gray-200 dark:border-gray-700 sticky top-0 z-10'>
      <Link to="/">
        <Navbar.Brand>
          <img src="./cdg.png" className="mr-3 h-10 sm:h-10`" alt="HappyDoc Diagnostics" />
          {/* <span className="self-center  whitespace-nowrap text-2xl font-semibold dark:text-white">
        HappyDoc Diagnostics
      </span> */}
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle />
      <Navbar.Collapse style={{ margin: '0 auto' }}>
        {navLinks.map((link, index) => (
          index === 0 ? (
            <Link key={index} to={link.path} style={{ margin: '0 10px' }}>
              <Navbar.Link className='text-md' to="/" active={link.active}>
                Home
              </Navbar.Link>
            </Link>
          ) : (
            !(link.name === "Login" && !isMobile) ? (
              <Link key={index} to={link.path} style={{ margin: '0 10px' }}>
                <Navbar.Link className='text-md' active={link.active}>
                  {link.name}
                </Navbar.Link>
              </Link>
            ) : null
          )
        ))}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarLink;
