import { useCallback, useEffect, useState } from "react";
import Select from 'react-select';
import { FaCheckCircle, FaHome } from 'react-icons/fa';
import { format } from 'date-fns';
import NavbarSidebarLayout from '../../../layouts/NavbarSidebarLayout';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Label,
  Table,
  Modal,
  TextInput,
  Pagination,
  Datepicker
} from "flowbite-react";
import { Link } from "react-router-dom";
import _ from 'lodash';


export default function Schedule() {
  const apiEndPoint = process.env.REACT_APP_API_URL;

  const [selectedDate, setSelectedDate] = useState(null);
  const [text, setText] = useState('');

  const currentDate = new Date();
  const currentHour = currentDate.getHours(); // Get current hour
  if (currentHour >= 17) { // Check if the current time is 6 PM or later
    currentDate.setDate(currentDate.getDate() + 3); // Add one day to the current date
  } else {
    currentDate.setDate(currentDate.getDate() + 2); // Add one day to the current date
  }

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page
  const [isOpen, setOpen] = useState(false);
  const [itemlist, setitemlist] = useState([]);
  const [formData, setFormData] = useState({
    payment_type_id: '',
    payment_type: '',
    payment_fee: '',
    payment_reference: '',
    patient_id: '',
    schedule_date: '',
  });
  const editAppointment = async (item) => {
    console.log(item)
    var payment = paymentTypeListoptions.find(option => option.value === item.payment_type_id)
    setFormData({
      ...formData,
      payment_fee: 300,
      payment_type: item.payment_type, // Update zipcode in formData with the selected option's 
      patient_id: item.id, // Update zipcode in formData with the selected option's 
      payment_type_id: parseInt(item.payment_type_id), // Update zipcode in formData with the selected option's 
      payment_account: payment.account, // Update zipcode in formData with the selected option's 
      schedule_date: currentDate

    });

    setOpen(true)
  };
  const [filter, setFilter] = useState('');
  const [filterReference, setfilterReference] = useState('');
  const [filterType, setfilterType] = useState('');
  const [paymentTypeList, setpaymentTypeList] = useState([]);
  const search = (value, type) => {
    if (type === 'reference') {
      setfilterReference(value)
      setfilterType(type)
    } else if (type === 'date') {
      setFilter(value)
      setfilterType(type)
    }
  };
  const itemListoptions = itemlist.map(item => ({
    id: item.patient_id, // Define the value for each option
    reference_no: item.reference_no, // Define the value for each option
    doctor_name: item.doctor_name, // Define the value for each option
    name: item.patient_name, // Define the value for each option
    date_schedule: format(item.date_schedule, 'yyyy-MM-dd'), // Define the value for each optionregistration_type
    registration_type: item.registration_type.name, // Define the value for each optionregistration_type
    amount: parseFloat(item.total_amount) + Math.max(item.rebook_fee), // Define the value for each option
    penalty: item.penalty, // Define the value for each option
    payment_type: item.paymenttype.name, // Define the value for each option
    payment_type_id: item.paymenttype.id, // Define the value for each option
    status: item.status, // Define the value for each option
    rebook_refnum: item.rebook_refnum, // Define the value for each option
    rebook_fee: item.rebook_fee, // Define the value for each option
    process_by: item.process_by, // Define the value for each option
  }));
  const filteredItems = itemListoptions.filter(item => {
    if (filterType === 'reference') {
      return item.reference_no.toLowerCase().includes(filterReference.toLowerCase()) ||
        item.doctor_name.toLowerCase().includes(filterReference.toLowerCase());
    } else if (filterType === 'date') {
      return item.date_schedule.toLowerCase().includes(filter.toLowerCase())
    } else {
      return true;
    }
  }
  );
  const paginate = (array, page_size, page_number) => {
    const paginatedArray = _.chunk(array, page_size);
    return paginatedArray[page_number - 1] || [];
  };
  const paginatedItems = paginate(filteredItems, pageSize, currentPage);


  const handlePaymentTypeChange = selectedOption => {
    setFormData({
      ...formData,
      payment_type: parseInt(selectedOption.value), // Update zipcode in formData with the selected option's 
      payment_account: selectedOption.account, // Update zipcode in formData with the selected option's 
      payment_type_name: selectedOption.label, // Update zipcode in formData with the selected option's 
    });
  }

  const changeReference = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      payment_reference: value
    });
  };

  const fetchPaymentTypeList = useCallback(async () => {
    try {
      const response = await fetch(`${apiEndPoint}get-payment-type`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const result = await response.json();
      setpaymentTypeList(result.data)
    } catch (error) {
      console.error(error);
    }
  }, [apiEndPoint]);


  const paymentTypeListoptions = paymentTypeList.map(item => ({
    value: parseInt(item.id), // Define the value for each option
    label: item.name, // Define the label (display text) for each option
    account: item.account
  }));
  // Get paginated items
  const formatNumber = (amount) => {
    if (!amount) return parseFloat(0);
    return amount.toLocaleString('en-PH', {
      style: 'currency',
      currency: 'PHP',
    });
  }
  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const fetchProcedureList = useCallback(async () => {
    try {
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData) {
        const response = await fetch(`${apiEndPoint}get-appointment-list?patient_id=` + userData.patient_id);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        if (data) {
          setitemlist(data.data.list)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [apiEndPoint]);


  const [isFullBook, setisFullBook] = useState(false);
  const [isFullBookBtn, setisFullBookBtn] = useState(false);
  const handleDateChange = async (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setFormData({ ...formData, schedule_date: formattedDate });
    setSelectedDate(date);
    try {
      const response = await fetch(`${apiEndPoint}check-schedule`, {
        method: 'POST', // Assuming this is a POST request based on your previous examples
        headers: {
          'Content-Type': 'application/json', // Adjust content type as needed
          // Add other headers if required
        },
        body: JSON.stringify({
          date_schedule: formattedDate,
        })
      }
      );
      setText('Sorry, this schedule is fully booked.')
      const result = await response.json();
      if (parseInt(result.data) >= process.env.REACT_APP_TOTAL_BOOK) {
        setisFullBook(true)
        setisFullBookBtn(true);
      } else {
        setisFullBookBtn(false);
        setisFullBook(false)
      }
    } catch (error) {
    } finally {
    }
  };
  const submitReschedule = async (e) => {
    e.preventDefault();
    const response = await fetch(`${apiEndPoint}reschedule`, {
      method: 'POST', // Assuming this is a POST request based on your previous examples
      headers: {
        'Content-Type': 'application/json', // Adjust content type as needed
        // Add other headers if required
      },
      body: JSON.stringify({
        payload: formData,
      })
    }
    );
    const result = await response.json();
    if (result.data) {
      setText('Successfully Changed')


      setOpen(false)
      setisFullBook(true)
      fetchProcedureList();
    }
  }
  const checkstatus = (item) => {
    if (item.process_by && !item.rebook_fee && !item.rebook_refnum) {
      return 'Confirmed';
    } else if (item.process_by && item.rebook_fee && item.rebook_refnum) {
      return 'Confirmed ';
    } else {
      return 'Pending';
    }
  }
  useEffect(() => {
    // fetchProcedureList();
    fetchPaymentTypeList();
  }, [fetchPaymentTypeList, fetchProcedureList]);
  // Handle pagination change
  return (

    <NavbarSidebarLayout>
      <div className="h-screen w-full">
        <div className="block items-center  w-full justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
          <div className="mb-1 w-full">
            <div className="mb-4">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item as={Link} to="/appointment">
                  <div className="flex items-center gap-x-3">
                    <FaHome className="text-xl" />
                    <span className="dark:text-white">Patient</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item as={Link} to="/appointment">
                  Appointment
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
              <div className="w-full">
                <div className="relative">
                  <Label htmlFor="appointment-search-1">
                    Reference No
                  </Label>
                  <div className="mt-1">
                    <TextInput
                      id="appointment-search-1"
                      name="appointment-search-1"
                      placeholder="Search for reference or doctor"
                      value={filterReference}
                      onChange={(e) => search(e.target.value, 'reference')}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="relative">
                  <Label htmlFor="appointment-search-1">
                    Schedule Date
                  </Label>
                  <div className="mt-1">
                    <TextInput
                      id="appointment-search-1"
                      type="date"
                      name="appointment-search-1"
                      placeholder="Search for appointment"
                      value={filter}
                      onChange={(e) => search(e.target.value, 'date')}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800 ">
          <div className="overflow-x-auto">
            <Table >
              <Table.Head>
                <Table.HeadCell className="p-4">
                  <Checkbox />
                </Table.HeadCell>
                <Table.HeadCell>Reference No.</Table.HeadCell>
                <Table.HeadCell>Patient Name</Table.HeadCell>
                <Table.HeadCell>Schedule Date</Table.HeadCell>
                <Table.HeadCell>Doctor Name</Table.HeadCell>
                <Table.HeadCell>Booking Type</Table.HeadCell>
                <Table.HeadCell>Paid Amount</Table.HeadCell>
                <Table.HeadCell>Payment Type</Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>
                <Table.HeadCell></Table.HeadCell>
                <Table.HeadCell></Table.HeadCell>

              </Table.Head>
              <Table.Body className="divide-y">
                {paginatedItems.map(item => (
                  <Table.Row key={item.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="p-4">
                      <Checkbox />
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{item.reference_no}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{item.name}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{item.date_schedule}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{item.doctor_name}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{item.registration_type}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{formatNumber(parseFloat(item.amount))}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{item.payment_type}</Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {checkstatus(item)}
                    </Table.Cell>
                    <Table.Cell>
                      <Button onClick={() => editAppointment(item)} small className="font-medium  hover:underline dark:text-cyan-500">
                        Change
                      </Button>

                    </Table.Cell>
                    <Table.Cell>
                      {/* <Button onClick={() => editAppointment(item)} small className="font-medium  hover:underline dark:text-cyan-500">
                        {'Details'}
                      </Button> */}

                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>

        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredItems.length / pageSize)}
          onPageChange={handlePageChange}
        />

        <Modal onClose={() => setOpen(false)} size="2xl" show={isOpen}>
          <Modal.Header >
            <strong>Re-schedule Appointment</strong>
          </Modal.Header>
          <div className="border border-b mt-2 border-gray-200"></div>
          <form onSubmit={submitReschedule}>
            <Modal.Body>
              <div className="grid grid-cols-1 gap-6 lg:grid-cols-1">

                <div>
                  <div className="w-full items-center justify-center flex" >
                    <div>
                      <img
                        src={`http://cdgpatient-portal.cebudocgroup.com.ph/payment-qr/maya.jpg`}
                        alt=""
                        className="w-64"
                      />
                      <p className='text-sm lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center'>SCAN {formData.payment_type_name} QR</p>
                      <h1 className='text-2xl lg:text-md mb-3 font-bold tracking-tight text-gray-900 dark:text-white text-center'>{formatNumber(parseFloat(300))}</h1>
                      <h1 className='text-xl lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center'>Re-Schedule Fee</h1>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 mt-3 gap-6 lg:grid-cols-2">
                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                      <label htmlFor="searchable-select" className="block mt-2 text-sm font-medium text-gray-900 dark:text-white">Payment Type <span className='text-red-100'>*</span> </label>
                      <Select id="searchable-select"
                        value={paymentTypeListoptions.find(option => option.value === formData.payment_type)}
                        onChange={handlePaymentTypeChange}
                        options={paymentTypeListoptions} />

                      {!formData.payment_type_id && <p className="text-red-500 text-sm">This field is required</p>}
                    </div>

                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                      <Label htmlFor="productName">Account Number</Label>
                      <TextInput
                        placeholder='Account Number'
                        readOnly
                        value={formData.payment_account}
                        type="text"
                      />
                    </div>
                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                      <Label htmlFor="productName">Schedule Date</Label>
                      {/* <TextInput
                        placeholder='Account Number'
                        type="date"
                      /> */}
                      <Datepicker
                        id="booking_date"
                        selected={selectedDate}
                        onSelectedDateChanged={handleDateChange}
                        minDate={currentDate}
                      />
                      {!formData.schedule_date && <p className="text-red-500 text-sm">Required</p>}
                    </div>

                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                      <Label htmlFor="productName">Reference Number</Label>
                      <TextInput
                        disabled={isFullBookBtn}
                        required
                        value={formData.payment_reference}
                        onChange={changeReference}
                        placeholder='Reference Number'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-end">
              <Button disabled={isFullBookBtn} type="submit">
                Save and Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal show={isFullBook} onClose={() => setisFullBook(false)} position="center" size="md" popup>
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <FaCheckCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {text}
              </h3>
              <div className="flex justify-center gap-4">
                <Button onClick={() => setisFullBook(false)} >
                  {"Close"}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </NavbarSidebarLayout>
  );
}
