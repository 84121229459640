import React from 'react';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import PatientNavigation from '../data/PatientNavigation';

const BottomNavigationComponent = ({ isMobile, selectedItem, setSelectedItem }) => {
  if (isMobile) {
    return (
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={selectedItem}
          onChange={(event, newValue) => {
            setSelectedItem(newValue);
          }}
        >
          {PatientNavigation.map((navItem) => (
            <BottomNavigationAction
              key={navItem.name}
              label={navItem.name}
              value={navItem.name}
              icon={navItem.icon}
            />
          ))}
        </BottomNavigation>
      </Paper>
    );
  }
  return null;
};

export default BottomNavigationComponent;
