import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Label,
  Table,
  TextInput,
  Pagination,
} from "flowbite-react";
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';


const Cards = ({ name, desc }) => {
  // Generate a dynamic image path based on the name (assuming images are named similarly)
  const imagePath = 'doctors.png';
  const linkPath = '/online-results';
  return (
    <Grid item xs={6} sm={3} lg={2} xl={1.5} md={3} className='animated animatedFadeInUp fadeInUp'>
      <Card
        sx={{
          borderRadius: '16px', // Adjust as needed
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adjust as needed
          transition: '0.5s',
          padding: 0.2,
          '&:hover': {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Adjust as needed for hover effect
          },
        }}>
        <CardMedia
          component="img"
          height="194"
          image={imagePath}
          alt="Paella dish"
        />
        <CardContent>
          <Typography gutterBottom variant="span" className='text-sm lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center' component="div">
            {name.length > 15 ? `${name.slice(0, 15)}...` : name}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
    // <Card className="max-w-sm w-full animated animatedFadeInUp fadeInUp" >
    //   <img
    //     src={imagePath}
    //     alt={name}
    //     className="w-full md:h-28 lg:h-48"
    //   />
    //   <p className='text-sm  font-bold tracking-tight text-gray-900 dark:text-white text-center'>{name}</p>
    //   <p className='text-sm  text-gray-900 dark:text-white text-center'>{desc.length > 15 ? `${desc.slice(0, 15)}...` : desc}</p>
    // </Card>
  );
};
function Doctors({ isMobile }) {

  const apiEndPoint = process.env.REACT_APP_API_URL;
  const [doctorList, setdoctorList] = useState([]);
  // const [filter, setFilter] = useState('');
  const [filterReference, setfilterReference] = useState('');
  // const [filterType, setfilterType] = useState(''); 

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 16; // Number of items per page
  const doctorListoptions = doctorList.map(item => ({
    doctorCode: item.doctorCode, // Define the value for each option
    doctor_name: item.doctor_name, // Define the label (display text) for each option
    doctor_specialty: item.mscSpecializationCode1
  }));
  const filteredItems = doctorListoptions.filter(item => {
    return item.doctorCode.toLowerCase().includes(filterReference.toLowerCase()) || item.doctor_name.toLowerCase().includes(filterReference.toLowerCase());
  });
  const search = (value, type) => {

    if (type === 'reference') {
      setfilterReference(value);
      // setfilterType(type);
    } else if (type === 'date') {
      // setFilter(value);
      // setfilterType(type);
    }
  };
  const paginate = (array, page_size, page_number) => {
    const paginatedArray = _.chunk(array, page_size);
    return paginatedArray[page_number - 1] || [];
  };
  const paginatedItems = paginate(filteredItems, pageSize, currentPage);

  const fetchDoctorList = useCallback(async () => {
    if (doctorList.length > 0) return;
    try {
      const response = await fetch(`${apiEndPoint}get-msc-doctors`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setdoctorList(data.data);
    } catch (error) {
      console.error(error);
    }
  }, [apiEndPoint, doctorList.length]);


  useEffect(() => {
    fetchDoctorList();
  }, [fetchDoctorList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className='container mx-auto'>
      <div className="block items-center p-6 w-full  border-b border-gray-200  dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
            <div className="w-full">
              <div className="relative">
                <Label htmlFor="appointment-search-1 animated animatedFadeInUp fadeInUp">
                  Doctors Name
                </Label>
                <div className="mt-1">
                  <TextInput
                    id="appointment-search-1"
                    name="appointment-search-1"
                    placeholder="Search for reference or doctor"
                    value={filterReference}
                    onChange={(e) => search(e.target.value, 'reference')}
                    className="w-full animated animatedFadeInUp fadeInUp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="grid grid-cols-2 gap-5 px-6 py-5 md:grid-cols-4 sm:grid-cols-5 lg:grid-cols-5 col-span-4">
        {paginatedItems.map((item, index) =>
          index === 0 ? null : <div class="col-span-1 sm:col-span-2 lg:col-span-1"><Cards key={index} name={item.doctor_name} desc={item.doctor_specialty} /></div>
        )}
      </div> */}

      {/* <div className="overflow-x-auto border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
        <div className="pa-5  p-2 dark:bg-blue-400 w-full">
          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 sm:grid-cols-4 lg:grid-cols-4 col-span-4">
            {paginatedItems.map((item, index) =>
              index === 0 ? null : <Cards key={index} name={item.doctor_name} desc={item.doctor_specialty} />
            )}
          </div>
        </div>
      </div> */}
      <Box sx={{ flexGrow: 1, m: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          {paginatedItems.map((item, index) =>
            index === 0 ? null : <Cards key={index} name={item.doctor_name} desc={item.doctor_specialty} />
          )}


        </Grid>
        <div className="flex justify-center p-4 mt-4 mb-4">
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(filteredItems.length / pageSize)}
            onPageChange={handlePageChange}
          />
        </div>
      </Box>
      
    </div>
  );
}

export default Doctors;
