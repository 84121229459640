import React from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  // Check if the current route is '/login'
  const isLoginRoute = location.pathname === '/login';

  // const currentYear = new Date().getFullYear(); 
  // If it's the login route, do not render the navbar
  if (isLoginRoute) {
    return null;
  }
  return (
    <div></div>
    // <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
    //   <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
    //     <div className="sm:flex sm:items-center sm:justify-between">
    //       <a href="https://flowbite.com/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
    //         </a>
    //       <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
    //         <li>
    //           <a href="#" className="hover:underline me-4 md:me-6">About</a>
    //         </li>
    //         <li>
    //           <a href="#" className="hover:underline me-4 md:me-6">Privacy Policy</a>
    //         </li>
           
    //         <li>
    //           <a href="#" className="hover:underline">Contact</a>
    //         </li>
    //       </ul>
    //     </div>
    //     <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    //     <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© {currentYear} All Rights Reserved.</span>
    //   </div>
    // </footer>
  );
};

export default Footer;
