// src/data/navLinks.js
const navBarMenu = [
  { description:'Home', name: 'HOME', image: '../booking-calendar.png', path: '/' },
  { description:'Online Booking Services', name: 'Online Booking', image: '../booking-calendar.png', path: '/online-booking' },
  // { description:'Services', name: 'Services', image: 'booking-calendar.png', path: '/online-booking' },
  { description:'Location', name: 'Location', image: 'doctors.png', path: '/location' },
  { description:'User guide', name: 'User Guide', image: 'user-guide.png', path: '/users-guide' },
  { description:'Online results', name: 'Login', image: '../result.png', path: '/login' },
];

export default navBarMenu;
