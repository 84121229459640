import React from 'react';
import { Button } from "flowbite-react";
export default function Complete({bookingresult,formatNumber}) {

  const handleDownloadClick = () => {
    window.open('http://cdgemrportal.cebudocgroup.com.ph/download-booking-reference?id='+ bookingresult.id, '_blank');
  };
  return (
    <div>
       <div className="flex items-center justify-center p-4 sm:p-6 lg:p-8">
      <div className="w-full max-w-2xl">
        <div className="text-3xl text-center mb-4">
          {bookingresult.reference_data}
        </div>
        <div className="text-2xl text-center mb-4">Reference number</div>
        <div className="border-t border-gray-200 my-2"></div>
        <div className="text-md mb-2">
          Your booking has been successfully submitted!
        </div>
        <div className="text-md mb-2">Booking Details</div>
        <div className="border-t border-gray-200 my-2"></div>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            <tr>
              <td className="font-bold px-2 py-2 w-36">Patient Name</td>
              <td className="font-semibold text-left px-2 py-2">
                : {bookingresult.patientname.toUpperCase()}
              </td>
            </tr>
            <tr>
              <td className="font-bold px-2 py-2">Date of Birth</td>
              <td className="font-semibold text-left px-2 py-2">
                : {bookingresult.birthdate}
              </td>
            </tr>
            <tr>
              <td className="font-bold px-2 py-2">Doctor Name</td>
              <td className="font-semibold text-left px-2 py-2">
                : {bookingresult.doctor_name}
              </td>
            </tr>
            <tr>
              <td className="font-bold px-2 py-2">Payment Method</td>
              <td className="font-semibold text-left px-2 py-2">
                : {bookingresult.payment_type_name}
              </td>
            </tr>
            <tr>
              <td className="font-bold px-2 py-2">Payment Status</td>
              <td className="font-semibold text-left px-2 py-2">: Paid</td>
            </tr>
            <tr>
              <td className="font-bold px-2 py-2">Booking Status</td>
              <td className="font-semibold text-left px-2 py-2">: Pending</td>
            </tr>
            <tr>
              <td className="font-bold px-2 py-2">Type</td>
              <td className="font-semibold text-left px-2 py-2">
                : {bookingresult.registration_type_name}
              </td>
            </tr>
            <tr>
              <td className="font-bold px-2 py-2">Date Visit</td>
              <td className="font-semibold text-left px-2 py-2">
                : {bookingresult.date_schedule}
              </td>
            </tr>
            <tr>
              <td className="font-bold px-2 py-2">Amount</td>
              <td className="font-semibold text-left px-2 py-2">
                : {formatNumber(parseFloat(bookingresult.total_amount))}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="border-t border-gray-200 my-2"></div>
          <label className="font-bold">To change your schedule, use the following login information:</label>
          <ol className="ps-5 mt-2 space-y-1 list-decimal list-inside">
            <li>
              Username :  {bookingresult.username}
            </li>
            <li>
              Password :  {bookingresult.password}
            </li>
          </ol>
        <div className="border-t border-gray-200 my-2"></div>
        <div className="flex justify-between items-center mb-4">
          <h1 className="font-semibold text-left px-2 py-2">
            Print or copy your reference number
          </h1>
          <Button className="mt-1" onClick={handleDownloadClick}>Download</Button>
        </div>
        <div className="border-t border-gray-200 my-2"></div>
        <div>
          <label className="font-bold">Important Reminders</label>
          <ol className="ps-5 mt-2 space-y-1 list-decimal list-inside">
            <li>
              Present your Reference No. to the guard upon entry at the {bookingresult.registration_type_name}.
            </li>
            <li>Follow the {bookingresult.registration_type_name} signs.</li>
            <li>
              Check your email for your EXPRESS DIAGNOSTICS BOOKING status.
            </li>
          </ol>
        </div>
      </div>
    </div>
    </div>
  );
}
