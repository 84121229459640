import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SearchIcon from '@mui/icons-material/Search';

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ResultCard from './Card/ResultCard';
// Example data
const allRows = [
  { id: 1, category: 'Laboratory', labNo: 'LAB001', orderDate: '2024-07-15', patientId: 'P001', patientName: 'John Doe', gender: 'Male', download: 'Download Link' },
  { id: 2, category: 'Cardiology', labNo: 'LAB002', orderDate: '2024-07-14', patientId: 'P002', patientName: 'Jane Smith', gender: 'Female', download: 'Download Link' },
  { id: 3, category: 'Radiology', labNo: 'LAB003', orderDate: '2024-07-13', patientId: 'P003', patientName: 'Michael Johnson', gender: 'Male', download: 'Download Link' },
  // Add more rows as needed
];

const ResultContent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchLabNo, setSearchLabNo] = useState('');
  const [searchOrderDate, setSearchOrderDate] = useState('');
  const [searchPatientName, setSearchPatientName] = useState('');
  const [category, setCategory] = useState('All');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchLabNoChange = (event) => {
    setSearchLabNo(event.target.value);
    setPage(0); // Reset page to first when searching
  };

  const handleSearchOrderDateChange = (event) => {
    setSearchOrderDate(event.target.value);
    setPage(0); // Reset page to first when searching
  };

  const handleSearchPatientNameChange = (event) => {
    setSearchPatientName(event.target.value);
    setPage(0); // Reset page to first when searching
  };

  const handleTabChange = (event, newValue) => {
    setCategory(newValue);
    setPage(0); // Reset page when switching tabs
  };

  // Filter rows based on selected category
  const filteredRows = category === 'All' ? allRows :
    allRows.filter(row => row.category === category);

  // Apply search filters
  const filteredResults = filteredRows.filter(row =>
    row.labNo.toLowerCase().includes(searchLabNo.toLowerCase()) &&
    row.orderDate.toLowerCase().includes(searchOrderDate.toLowerCase()) &&
    row.patientName.toLowerCase().includes(searchPatientName.toLowerCase())
  );
  const OrderDateFilter = () => {
    if (isMobile) return
    return (
      <>
        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Search Lab No..."
            className="border border-gray-300 rounded-lg px-3 py-2 w-full focus:outline-none focus:border-blue-500"
            onChange={handleSearchLabNoChange}
            value={searchLabNo}
          />
        </div>
      </>
    )
  }
  const LabNoFilter = () => {
    if (isMobile) return
    return (
      <>
        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Search Order Date..."
            className="border border-gray-300 rounded-lg px-3 py-2 w-full focus:outline-none focus:border-blue-500"
            onChange={handleSearchOrderDateChange}
            value={searchOrderDate}
          />
        </div>
      </>
    )
  }
  const DescriptionFilter = () => {
    if (isMobile) return
    return (
      <>
        <div className="flex flex-col">
          <input
            type="text"
            placeholder="Search Patient Name..."
            className="border border-gray-300 rounded-lg px-3 py-2 w-full focus:outline-none focus:border-blue-500"
            onChange={handleSearchPatientNameChange}
            value={searchPatientName}
          />
        </div>
      </>
    )
  }


  const DesktopTableView = ({ filteredResults }) => (
    <div>
      <table className="min-w-[650px] w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="border-b border-gray-300">
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lab No</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Date</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Patient ID</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Patient Name</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gender</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Download</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <tr key={row.id} className="border-b border-gray-200 hover:bg-gray-100 transition-colors duration-300">
              <td className="px-6 py-4 whitespace-nowrap">{row.labNo}</td>
              <td className="px-6 py-4 whitespace-nowrap">{row.orderDate}</td>
              <td className="px-6 py-4 whitespace-nowrap">{row.patientId}</td>
              <td className="px-6 py-4 whitespace-nowrap">{row.patientName}</td>
              <td className="px-6 py-4 whitespace-nowrap">{row.gender}</td>
              <td className="px-6 py-4 whitespace-nowrap">{row.download}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div >
      {/* Tabs */}
      <div style={{ margin: 2 }}>
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={category} 
          onChange={handleTabChange}
          aria-label="scrollable force tabs example"
        >
          <Tab label="All" value="All" />
          <Tab label="Laboratory" value="Laboratory" />
          <Tab label="Cardiology" value="Cardiology" />
          <Tab label="Imaging" value="Radiology" />
        </Tabs>
      </div>


      <div>
        {/* Search inputs */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
          <OrderDateFilter />
          <LabNoFilter />
          <DescriptionFilter />
        </div>
        {/* Table */}

        <div >
          {isMobile ? (
            <div className="block md:hidden"> {/* Show cards on mobile */}
              {/* {filteredResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <ResultCard key={row.id} row={row} />
              ))} */}
            </div>
          ) : <DesktopTableView filteredResults={filteredResults} />}
        </div>

        {/* Pagination */}
        <div className="p-4 flex justify-end">
          <span className="text-sm text-gray-500">Rows per page:</span>
          <select
            className="ml-2 border border-gray-300 rounded-lg px-3 py-1 focus:outline-none focus:border-blue-500"
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(parseInt(e.target.value, 10));
              setPage(0);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
          </select>
        </div>
      </div>
    </div >
  );
};

export default ResultContent;
