import Select from 'react-select';
import React from 'react';
import { TextInput,Label,FileInput } from 'flowbite-react';

export default function Payment({
    formFee,
    paymentTypeList,
    handlePaymentTypeChange,
    formatNumber,
    handlePaymentChange,
    initialReference,
    handleSubmitFee,
    apiEndPoint,
    setFormFeeData
}) {

    const paymentTypeListoptions = paymentTypeList.map(item => ({
        value: parseInt(item.id), // Define the value for each option
        label: item.name, // Define the label (display text) for each option
        account: item.account
    }));
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormFeeData({
                ...formFee,
                payment_attachment: file,
            });
        }
    }
    return (
        <div>
            <form onSubmit={handleSubmitFee}>
                <div className="grid grid-cols-3 gap-4 md:grid-cols-3 sm:grid-cols-3 lg:grid-cols-3">
                    {/* <div className="col-span-3 md:col-span-1 sm:col-span-1">
                        <div className="w-full" >
                            <img
                                src={`http://cdgpatient-portal.cebudocgroup.com.ph/payment-qr/maya.jpg`}
                                alt={formFee.payment_type_name}
                                className="w-screen"
                            />
                            <p className='text-sm lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center'>SCAN {formFee.payment_type_name} QR</p>
                            <h1 className='text-2xl lg:text-md mb-3 font-bold tracking-tight text-gray-900 dark:text-white text-center'>{formatNumber(parseFloat(formFee.payment_amount))}</h1>
                            <h1 className='text-xl lg:text-lg font-bold tracking-tight text-gray-900 dark:text-white text-center'>Amount to be Paid</h1>
                        </div>
                    </div> */}
                    <div className="col-span-3 md:col-span-1 sm:col-span-1"> 
                        <div className="col-span-3 md:col-span-1 sm:col-span-1">
                            <label htmlFor="searchable-select" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Payment Type <span className='text-red-100'>*</span> </label>
                            <Select id="searchable-select"
                                value={paymentTypeListoptions.find(option => option.value === formFee.payment_type)}
                                onChange={handlePaymentTypeChange}
                                options={paymentTypeListoptions} />

                            {!formFee.payment_type && <p className="text-red-500 text-sm">This field is required</p>}
                        </div>
                        <div className="col-span-3 md:col-span-1 mt-2 sm:col-span-1">
                            <label htmlFor="accountnumber" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Account Number</label>
                            <TextInput
                                type="text"
                                id="accountnumber"
                                readOnly
                                placeholder="Account Number"
                                required
                                value={formFee.payment_account}
                            />
                        </div>
                        <div className="col-span-3 md:col-span-1 mt-2 sm:col-span-1">
                            <label htmlFor="amounttobepaid" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Amount to be Paid</label>
                            <TextInput
                                type="text"
                                id="amounttobepaid"
                                readOnly
                                placeholder="Amount "
                                required
                                value={parseFloat(formFee.payment_amount).toFixed(2)}
                            />
                        </div>

                        <div className="col-span-3 md:col-span-1 mt-2 sm:col-span-1">
                            <label htmlFor="referenceno" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Reference No</label>
                            <TextInput
                                type="text"
                                id="referenceno"
                                placeholder="Enter Reference No"
                                required
                                value={formFee.payment_reference}
                                onChange={handlePaymentChange}
                            />
                            {!formFee.payment_reference && <p className="text-red-500 text-sm">This field is required</p>}
                        </div>
                        <div className="col-span-3 md:col-span-1 mt-2 sm:col-span-1">
                        <   div>
                                <Label htmlFor="file-upload-helper-text" value="Upload Proof of Payment" />
                            </div>
                            <FileInput onChange={handleFileChange} accept="image/png" id="file-upload-helper-text" helperText="File type .jpg or .png only (Max 3MB)" />
                            {!formFee.payment_attachment && <p className="text-red-500 text-sm">This field is required</p>}
                        </div>
                    </div>
                    <div className="col-span-3 md:col-span-1 sm:col-span-1">
                        <div>
                           
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
