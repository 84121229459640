import React from 'react';
// import { Link } from 'react-router-dom';
// import { Card } from "flowbite-react";

// const Cards = ({ isMobile, name, path, imagePath, desc, mobile }) => {
//   // Generate a dynamic image path based on the name (assuming images are named similarly)
//   const linkPath = mobile && path === '/online-results' ? '/login' : path;
//   return (
//     <Link to={linkPath}>
//       <Card className="max-w-sm w-full animated animatedFadeInUp fadeInUp" >
//         <img
//           src={imagePath}
//           alt={name}
//           className="w-full md:h-28 lg:h-48"
//         />
//         <p className='text-sm lg:text-sm font-bold tracking-tight text-gray-900 dark:text-white text-center'>{name}</p>
//       </Card>
//     </Link>
//   );
// };
const HomeContent = () => {
  return (
    <div>Home content goes here...</div>
    // <div className="col-span-2  md:col-span-1 sm:col-span-2  max-w-3xl w-full">
    //   <div className="grid grid-cols-2 gap-2 md:grid-cols-3 sm:grid-cols-4 lg:grid-cols-4 col-span-4">
    //     {navLinks.map((link, index) =>
    //       index === 0 ? null : <Cards key={index} name={link.name} path={link.path} imagePath={link.image} desc={link.description} mobile={true} />
    //     )}
    //   </div>
    // </div>
  );
};

export default HomeContent;
