import React, { useEffect, useCallback, useState, useMemo } from 'react';
import Select from 'react-select';
import { Select as Selects, Label, TextInput } from "flowbite-react";
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
export default function BasicInfo({
    errors,
    formData,
    handleChange,
    handleSubmit,
    refs,
    setFormData,
    apiEndPoint,
    categoryList,
    handleChangeSenior,
    handleChangecategory
}) {
    const filteredCategoryList = Math.max(formData.age) <= 60
    ? categoryList.filter(item => item.value !== '2')
    : categoryList;
    const [sexList, setSexList] = useState([]);
    const [zipcodeList, setzipcodeList] = useState([]);
    const [religionList, setreligionList] = useState([]);
    const [civilStatusList, setcivilStatusList] = useState([]);
    const [nationalityList, setnationalityList] = useState([]);
    const [barangayList, setbarangayList] = useState([]);
    const requestOptions = useMemo(() => ({
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
    }), []);

    const zipcodeListoptions = zipcodeList.map(item => ({
        value: item.zipCode, // Define the value for each option
        label: item.zip_code_details, // Define the label (display text) for each option
        province_id: item.get_province ? item.get_province.provinceCode : '', // Define the label (display text) for each option
        municipality_id: item.get_municipality ? item.get_municipality.municipalityCode : '', // Define the label (display text) for each option
        municipality_code: item.municipalityCode,  // Define the label (display text) for each option
        province_code: item.provinceCode, // Define the label (display text) for each option
        region_code: item.regionCode, // Define the label (display text) for each option

        province_name: item.get_province ? item.get_province.provinceName : '', // Define the label (display text) for each option
        municipality_name: item.get_municipality ? item.get_municipality.municipalityName : '', // Define the label (display text) for each option
    }));

    const barangayListoptions = [
        { value: '', label: 'Select Barangay' },
        ...barangayList.map(item => ({
            value: item.mscAddrBarangayID,
            label: item.barangayName
        }))
    ];

    const handleChanges = (e) => {
        const { value } = e.target;
        const birthdate = new Date(value);
        console.log(birthdate);
        const today = new Date();
        let age = today.getFullYear() - birthdate.getFullYear();
        const monthDiff = today.getMonth() - birthdate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
            age--;
        }
        setFormData({
            ...formData,
            birthdate: value,
            age: age
        });
    }

    const fetchSexList = useCallback(async () => {
        if (sexList.length > 0) return;
        try {
            const response = await fetch(`${apiEndPoint}sex-list`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setSexList(data);
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint, sexList.length]);

    const fetchReligionList = useCallback(async () => {
        if (religionList.length > 0) return;
        try {
            const response = await fetch(`${apiEndPoint}get-msc-mscreligion`, requestOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setreligionList(data);
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint, religionList.length, requestOptions]);

    const fetchnationalityList = useCallback(async () => {
        if (nationalityList.length > 0) return;
        try {
            const response = await fetch(`${apiEndPoint}get-msc-mscnationality`, requestOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setnationalityList(data.nationality)
            setcivilStatusList(data.civilstatus)
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint, nationalityList.length, requestOptions]);


    const fetchzipcodeList = useCallback(async () => {
        if (zipcodeList.length > 0) return;
        try {
            const response = await fetch(`${apiEndPoint}get-zipcode`, requestOptions);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setzipcodeList(data.data)
        } catch (error) {
            console.error(error);
        }
    }, [apiEndPoint, zipcodeList.length, requestOptions]);


    const handlebarangayChange = selectedOption => {
        setFormData({
            ...formData,
            barangay: selectedOption.value, // Update zipcode in formData with the selected option's value
        });
    }


    const handlezicodeChange = selectedOption => {
        setFormData({
            ...formData,
            zipcode: selectedOption.value, // Update zipcode in formData with the selected option's value
            province_id: selectedOption.province_id, // Update zipcode in formData with the selected option's value
            municipality_id: selectedOption.municipality_id, // Update zipcode in formData with the selected option's value
            province_name: selectedOption.province_name, // Update zipcode in formData with the selected option's value
            municipality_name: selectedOption.municipality_name, // Update zipcode in formData with the selected option's value
            barangay: '',
            region_code: selectedOption.region_code
        });
        fetchbarangayList(selectedOption.municipality_code, selectedOption.province_code, selectedOption.region_code)
    };

    const fetchbarangayList = async (municipality_code, province_code, region_code) => {
        try {
            const response = await fetch(`${apiEndPoint}get-msc-getbarangay`,
                {
                    method: 'POST', // Assuming this is a POST request based on your previous examples
                    headers: {
                        'Content-Type': 'application/json', // Adjust content type as needed
                        // Add other headers if required
                    },
                    body: JSON.stringify({
                        region: region_code,
                        province: province_code,
                        municipality: municipality_code
                    })
                }
            );
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setbarangayList(data)
        } catch (error) {
        } finally {
        }
    };

    useEffect(() => {
        fetchSexList();
        fetchReligionList();
        fetchnationalityList();
        fetchzipcodeList();
    }, [fetchSexList, fetchReligionList, fetchnationalityList, fetchzipcodeList]);

    return (
        <div>
            <div style={{ minHeight: '68vh' }}>
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-3 gap-5 md:grid-cols-4 sm:grid-cols-3 lg:grid-cols-3 mx-auto animated animatedFadeInUp fadeInUp" >
                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <label htmlFor="lastname" className="block mb-1 text-sm font-medium animated animatedFadeInUp fadeInUp text-gray-900 dark:text-white">Last name</label>
                            <TextInput
                                type="text"
                                id="lastname"
                                placeholder="Last Name"
                                required
                                className="animated animatedFadeInUp fadeInUp"
                                value={formData.lastname}
                                onChange={handleChange}
                                ref={refs.lastname}
                            />
                            {!formData.lastname && errors.lastname && <p className="text-red-500 text-sm">{errors.lastname}</p>}
                        </div>
                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <label htmlFor="firstname" className="block mb-1 text-sm font-medium animated animatedFadeInUp fadeInUp text-gray-900 dark:text-white">First name</label>
                            <TextInput
                                type="text"
                                id="firstname"
                                placeholder="First name"
                                required
                                className="animated animatedFadeInUp fadeInUp"
                                value={formData.firstname}
                                onChange={handleChange}
                                ref={refs.firstname}
                            />
                            {!formData.firstname && errors.firstname && <p className="text-red-500 text-sm">{errors.firstname}</p>}
                        </div>
                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <label htmlFor="middlename" className="block mb-1 text-sm font-medium animated animatedFadeInUp fadeInUp text-gray-900 dark:text-white">Middle name</label>
                            <TextInput
                                type="text"
                                id="middlename"
                                placeholder="Middle name"
                                required
                                className="animated animatedFadeInUp fadeInUp"
                                value={formData.middlename}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <label htmlFor="birthdate" className="block mb-1 text-sm font-medium animated animatedFadeInUp fadeInUp text-gray-900 dark:text-white">Birth Date</label>
                            <TextInput
                                type="date"
                                id="birthdate"
                                required
                                className="animated animatedFadeInUp fadeInUp"
                                value={formData.birthdate}
                                onChange={handleChanges}
                                max={new Date().toISOString().split("T")[0]} // Sets max date to today's date
                                ref={refs.birthdate}
                            />
                            {!formData.birthdate && errors.birthdate && <p className="text-red-500 text-sm">{errors.birthdate}</p>}
                        </div>
                        <div className="col-span-3  md:col-span-2 sm:col-span-1">
                            <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">

                                <div className="col-span-1 md:col-span-1 sm:col-span-1">
                                    <label htmlFor="age" className="block mb-1 text-sm font-medium animated animatedFadeInUp fadeInUp text-gray-900 dark:text-white">Age</label>
                                    <TextInput
                                        type="text"
                                        id="age"
                                        placeholder="Age"
                                        readOnly
                                        value={Math.max(formData.age)}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col-span-1 md:col-span-1 sm:col-span-1">
                                    <div className="block">
                                        <Label htmlFor="sex" value="Sex" />
                                    </div>
                                    <Selects
                                        id="sex"
                                        required
                                        className="animated animatedFadeInUp fadeInUp"
                                        value={formData.sex}
                                        onChange={handleChange}
                                        ref={refs.sex}
                                    >
                                        <option value=''>Select</option>
                                        {sexList.map((sex) => (
                                            <option key={sex.mscSex_id} value={sex.mscSex_id}>
                                                {sex.description}
                                            </option>
                                        ))}
                                    </Selects>
                                    {!formData.sex && errors.sex && <p className="text-red-500 text-sm">{errors.sex}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3  md:col-span-2 sm:col-span-1">
                            <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="col-span-1 md:col-span-1 sm:col-span-1">
                                    <div className="block">
                                        <Label htmlFor="civilStatus" value="Civil Status" />
                                    </div>
                                    <Selects
                                        id="civilStatus"
                                        required
                                        className="animated animatedFadeInUp fadeInUp"
                                        value={formData.civilStatus}
                                        onChange={handleChange}
                                        ref={refs.civilStatus}
                                    >
                                        <option value=''>Select</option>
                                        {civilStatusList.map((item) => (
                                            <option key={item.mscCivilStatusID} value={item.mscCivilStatusID}>
                                                {item.description}
                                            </option>
                                        ))}
                                    </Selects>
                                    {!formData.civilStatus && errors.civilStatus && <p className="text-red-500 text-sm">{errors.civilStatus}</p>}
                                </div>
                                <div className="col-span-1 md:col-span-1 sm:col-span-1">
                                    <div className="block">
                                        <Label htmlFor="nationality" value="Nationality" />
                                    </div>
                                    <Selects
                                        id="nationality"
                                        required
                                        className="animated animatedFadeInUp fadeInUp"
                                        value={formData.nationality}
                                        onChange={handleChange}
                                        ref={refs.nationality}
                                    >
                                        <option value=''>Select</option>
                                        {nationalityList.map((item) => (
                                            <option key={item.mscNationalityid} value={item.mscNationalityid}>
                                                {item.nationality}
                                            </option>
                                        ))}
                                    </Selects>
                                    {!formData.nationality && errors.nationality && <p className="text-red-500 text-sm">{errors.nationality}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <div className="block">
                                <Label htmlFor="religion" value="Religion" />
                            </div>
                            <Selects
                                id="religion"
                                required
                                className="animated animatedFadeInUp fadeInUp"
                                value={formData.religion}
                                onChange={handleChange}
                                ref={refs.religion}
                            >
                                <option value=''>Select</option>

                                {religionList.map((item) => (
                                    <option key={item.mscReligionid} value={item.mscReligionid}>
                                        {item.description}
                                    </option>
                                ))}
                            </Selects>
                            {!formData.religion && errors.religion && <p className="text-red-500 text-sm">{errors.religion}</p>}
                        </div>
                        <div className="col-span-3  md:col-span-2 sm:col-span-1">
                            <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="col-span-1 md:col-span-1 sm:col-span-1">
                                    <label htmlFor="Mobile" className="block mb-1 text-sm font-medium animated animatedFadeInUp fadeInUp text-gray-900 dark:text-white">Mobile No</label>
                                    <TextInput
                                        type="tel"
                                        id="Mobile"
                                        placeholder="Mobile No"
                                        required
                                        className="animated animatedFadeInUp fadeInUp"
                                        value={formData.mobile}
                                        onChange={(e) => {
                                            let value = e.target.value.replace(/\D/g, ''); // Replace all non-numeric characters globally
                                            // Ensure the number starts with "09"
                                            if (value.length > 2 && !value.startsWith('09')) {
                                                value = '09' + value.slice(2); // Prepend "09" if it doesn't start with it
                                            }
                                            // Limit to 11 digits
                                            if (value.length > 11) {
                                                value = value.slice(0, 11); // Limit to 11 characters
                                            }
                                            setFormData({ ...formData, mobile: value });
                                        }}
                                        ref={refs.mobile}
                                    />

                                    {!formData.mobile && errors.mobile && <p className="text-red-500 text-sm">{errors.mobile}</p>}
                                </div>
                                <div className="col-span-1 md:col-span-1 sm:col-span-1">
                                    <label htmlFor="telno" className="block mb-1 text-sm font-medium animated animatedFadeInUp fadeInUp text-gray-900 dark:text-white">Tel</label>
                                    <TextInput
                                        type="tel"
                                        id="telno"
                                        placeholder="Tel No"
                                        required
                                        className="animated animatedFadeInUp fadeInUp"
                                        value={formData.telno}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-3  md:col-span-2 sm:col-span-1">
                            <div className="grid grid-cols-2 gap-4 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
                                <div className="col-span-1 md:col-span-1 sm:col-span-1">
                                    <div className="block">
                                        <Label htmlFor="category_id" value="Category" />
                                    </div>
                                    <Selects
                                        id="category_id"
                                        required
                                        className="animated animatedFadeInUp fadeInUp"
                                        value={formData.category_id}
                                        onChange={handleChangecategory}
                                        ref={refs.category_id}
                                    >
                                        {filteredCategoryList.map((item) => (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </Selects>
                                    {!formData.category_id && errors.category_id && <p className="text-red-500 text-sm">{errors.category_id}</p>}
                                </div>
                                {
                                    (Math.max(formData.age) >= 59 || parseInt(formData.category_id) !== 1) ? (
                                        <div className="col-span-1 md:col-span-1 sm:col-span-1">
                                            <label htmlFor="senior_id" className="block mb-1 text-sm font-medium animated animatedFadeInUp fadeInUp text-gray-900 dark:text-white">Senior ID / OSCA ID</label>
                                            <TextInput
                                                id="senior_id"
                                                placeholder="Senior ID / OSCA ID"
                                                required
                                                className="animated animatedFadeInUp fadeInUp"
                                                value={formData.senior_id}
                                                onChange={handleChangeSenior}
                                            />
                                            {!formData.senior_id && errors.senior_id && <p className="text-red-500 text-sm">{errors.senior_id}</p>}
                                        </div>
                                        
                                    ) : ('')
                                }

                            </div>
                        </div>
                    </div>
                    <h5 className="text-xl mt-3 mb-3 font-medium text-gray-900 dark:text-white">Contact Information</h5>
                    <div className='divide-y divide-gray-200 dark:divide-gray-700 '></div>
                    <div className="grid grid-cols-3 mt-3 gap-4 md:grid-cols-4 sm:grid-cols-3 lg:grid-cols-3">
                        <div className="col-span-3  md:col-span-2 sm:col-span-1">
                            <div className="block">
                                <Label htmlFor="email" value="Email" />
                            </div>
                            <TextInput
                                id="email"
                                type="email"
                                placeholder="patient@gmail.com"
                                required
                                className="animated animatedFadeInUp fadeInUp"
                                value={formData.email}
                                onChange={handleChange}
                                ref={refs.email}
                            />
                            {!formData.email && errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <div className="col-span-3  md:col-span-2 sm:col-span-1">
                            <div className="block">
                                <Label htmlFor="birthplace" value="Birth Place" />
                            </div>
                            <TextInput
                                id="birthplace"
                                type="text"
                                placeholder="Enter Birth Place"
                                required
                                value={formData.birthplace}
                                onChange={handleChange}
                                ref={refs.BirthPlace}
                            />
                            {!formData.birthplace && errors.birthplace && <p className="text-red-500 text-sm">{errors.birthplace}</p>}
                        </div>
                        <div className="col-span-3  md:col-span-2 sm:col-span-1">
                            <div className="block">
                                <Label htmlFor="streetbldg" value="Current Address Sitio/Subd/Street" />
                            </div>
                            <TextInput
                                id="streetbldg"
                                type="address"
                                placeholder="Enter Current Address Sitio/Subd/Street"
                                required
                                value={formData.streetbldg}
                                onChange={handleChange}
                                ref={refs.streetbldg}
                            />
                            {!formData.streetbldg && errors.streetbldg && <p className="text-red-500 text-sm">{errors.streetbldg}</p>}
                        </div>
                    </div>
                    <div className="grid grid-cols-3 mt-3 gap-4 md:grid-cols-4 sm:grid-cols-3 lg:grid-cols-3">
                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <div className="block">
                                <Label htmlFor="zipcode" value="ZipCode" />
                            </div>

                            <Select options={zipcodeListoptions}
                                id="zipcode"
                                required
                                value={zipcodeListoptions.find(option => option.value === formData.zipcode)}
                                onChange={handlezicodeChange}
                                ref={refs.zipcode}
                            />
                            {!formData.zipcode && errors.zipcode && <p className="text-red-500 text-sm">{errors.zipcode}</p>}
                        </div>
                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <div className="block">
                                <Label htmlFor="province_name" value="Province" />
                            </div>
                            <TextInput
                                id="province_name"
                                placeholder="Province"
                                required
                                readOnly
                                value={formData.province_name}
                                onChange={handleChange}
                                ref={refs.province_name}
                            />
                            {!formData.province_name && errors.province_name && <p className="text-red-500 text-sm">{errors.province_name}</p>}
                        </div>
                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <div className="block">
                                <Label htmlFor="municipality_name" value="Municipality" />
                            </div>
                            <TextInput
                                id="municipality_name"
                                placeholder="Municipality"
                                required
                                readOnly
                                value={formData.municipality_name}
                                onChange={handleChange}
                                ref={refs.municipality_name}
                            />
                            {!formData.municipality_name && errors.municipality_name && <p className="text-red-500 text-sm">{errors.municipality_name}</p>}
                        </div>

                        <div className="col-span-3  md:col-span-1 sm:col-span-1">
                            <div className="block">
                                <Label htmlFor="barangay" value="Barangay" />
                            </div>

                            <Select options={barangayListoptions}
                                id="barangay"
                                required
                                ref={refs.barangay}
                                value={barangayListoptions.find(option => option.value === formData.barangay)}
                                onChange={handlebarangayChange}
                            />

                            {!formData.barangay && errors.barangay && <p className="text-red-500 text-sm">{errors.barangay}</p>}
                        </div>
                    </div>
                    {/* <button type="submit" className="px-4 py-2 mt-4 text-white bg-blue-500 rounded disabled:bg-gray-400">
                    Submit
                </button> */}
                </form>
            </div>
        </div>

    );
}

