// src/data/navLinks.js
const ServicesList = [
    { description:'Home', name: 'Home', image: 'booking-calendar.png', path: '/' },
    { description:'Online results', name: 'Online Results', image: 'result.png', path: '/online-results' },
    { description:'Online Booking Services', name: 'Online Booking', image: 'booking-calendar.png', path: '/services' },
    { description:'Online Booking Services', name: 'Online Booking', image: 'booking-calendar.png', path: '/services' },
    { description:'Online Booking Services', name: 'Online Booking', image: 'booking-calendar.png', path: '/services' },
    { description:'See list of our doctors', name: 'Our Doctors', image: 'doctors.png', path: '/our-doctors' },
    { description:'Data Privacy and Policy', name: 'Data Privacy', image: 'privacy-policy.png', path: '/billing-statements' },
    { description:'User guide', name: 'User Guide', image: 'user-guide.png', path: '/billing-statements' },
  ];
  
  export default ServicesList;
  