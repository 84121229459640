import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Label, Modal } from 'flowbite-react';
import { Link } from 'react-router-dom';
export default function FastingTest({isModalOpen1,setisModalOpen1,fastingList}) {
    const closeFasting = () => {
        setisModalOpen1(false)
    }
    return (
        <div>
            <Modal show={isModalOpen1} onClose={closeFasting}>
                <Modal.Body>
                    <h2 className="font-semibold ">Laboratory Test Requires Fasting.</h2>
                    <div className="border border-b mt-2 border-gray-200"></div>
                    <div style={{ height: '70vh' }} className="overflow-y-auto">
                        <div className="relative overflow-x-auto">
                            <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <tbody>
                                    {fastingList.map((item, index) => (
                                        <tr key={index + 1} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <th className="font-medium p-2 text-gray-900 whitespace-nowrap dark:text-white">
                                                <div className='flex justify-between'>
                                                    <div>{index + 1}{'.'} {item.name}</div>
                                                    <div> {item.description}</div>
                                                </div>
                                            </th>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer className='flex justify-end'>
                    <Button className="sm" onClick={closeFasting}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
