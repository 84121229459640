// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import theme from "./flowbite-theme";
import { Flowbite } from "flowbite-react";
import Footer from './pages/footer/Footer';
import './Modal.css';
import NavbarLink from './pages/navbar/NavbarLink';
import AuthRoutes from './pages/navbar/AuthRoutes';
// import MainLayout from './components/MainLayout';


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Check authentication status from localStorage on mount
    const user = localStorage.getItem('user');
    setIsAuthenticated(!!user);
  }, []);

  return (

    // <MainLayout>
    //   <div>
    //     {/* <h1 className="text-3xl font-bold">Welcome to the Admin Panel</h1> */}
    //   </div>
    // </MainLayout>

    <Flowbite theme={{ theme }}>
      <Router>
        <div className="App">
          <NavbarLink />
          <AuthRoutes isAuthenticated={isAuthenticated} isMobile={isMobile} />
          <Footer />
        </div>
      </Router>
    </Flowbite>
  );
};

export default App;
