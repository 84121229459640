import React from 'react';

export default function Results() {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src="https://patient-portal-live.cebudocgroup.com.ph/login"
        style={{ height: '100%', width: '100%', border: 'none' }}
        title="Results"
      />
    </div>
  );
}
